import React, { useEffect, useState } from 'react';
import './UnsubscribeEmailPage.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UnsubscribeEmailView from '../UnsubscribeEmailView/UnsubscribeEmailView';
import App from '../App/App';

interface UnsubscribeEmailPageState {
}

interface UnsubscribeEmailPageProps extends vms.AuthenticatedUserProps {

}

//This page only shows when the user is authenticated and when the unsubscribe
//query variables are present
class UnsubscribeEmailPage extends React.Component<UnsubscribeEmailPageProps, UnsubscribeEmailPageState> {
    constructor(props: UnsubscribeEmailPageProps, state: UnsubscribeEmailPageState) {
        super(props);
        this.state = state;
    }

    render() {        
        return <div className='UnsubscribeEmailPage'>{this.BuildContent()}</div>
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const queryEmail = params.get("email")?.toLowerCase();
        const queryOtp = params.get("otp");

        if ((this.props.userInfo.email ?? "").toLowerCase() != queryEmail) {
            this.props.auth0.logout({ returnTo: window.location.href });
        }
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return <UnsubscribeEmailView />;
    }
}

export default UnsubscribeEmailPage;
