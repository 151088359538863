import React, { ReactNode } from 'react';
import './ApplicationModal.css';
import Modal from "react-bootstrap/Modal";
import $ from "jquery"
import DialogFormBase from '../DialogFormBase/DialogFormBase';
import LoadingWidget from '../LoadingWidget/LoadingWidget';

interface IModalResult {
    result?: string;
    value?: string;
}

interface IMessageBoxProperties {
    message?: string;
    title?: string;
    actions?: Array<string>;
    style?: ModalStyle;
    form?: any;
    initialValue?: string;
    onValidate?: (form: any) => boolean ;
    onValidateAsync?: (form: any) => Promise<boolean>;
    noActionHighlight?: boolean;
}

enum ModalStyle { None, Information, Warning, Error, Input, Form, Memo, Spin }

interface ApplicationModalState extends IMessageBoxProperties {
    result?: string;
    value: string;
    hide: boolean;
    accept?: (value: unknown) => void;
    cancel?: (reason: any) => void;
}

interface ApplicationModalProps {

}

class ApplicationModal extends React.Component<ApplicationModalProps, ApplicationModalState> {
    private static instance: ApplicationModal;
    private static OkCancelActions = ["OK", "CANCEL"];
    private static YesNoActions = ["Yes", "No"];
    private body: any | null;

    constructor(props: ApplicationModalProps, state: ApplicationModalState) {
        super(props);
        this.state = {
            message: undefined,
            value: "",
            hide: false
        };
        if (!ApplicationModal.instance == null) {
            throw new Error("An instance of ApplicationModal already exists. Please reference that instance using ApplicationModal.instance.");
        }
        ApplicationModal.instance = this;
    }

    render() {
        var title = this.getTitle();
        return (
            <Modal className='ApplicationModal' show={this.isVisible()}
                aria-labelledby="contained-modal-title-vcenter"
                onEscapeKeyDown={async e => await this.close("cancel")}
                centered>
                {title == null ? null : (
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div className='row full-width'>
                                <div className='col-11'>
                                    <ul className='primary-item-list'>
                                        <li>{title}</li>
                                    </ul>
                                </div>
                                {
                                    (this.state.actions == null || this.state.actions.length == 0)
                                    ? null
                                    : (
                                        <div className='col-1'>
                                            <a className='btn btn-secondary'>
                                                <i className='fa fa-xmark' onClick={e => {this.close("CANCEL")}}></i>
                                            </a>
                                        </div>    
                                    )
                                }
                            </div>

                        </Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body ref={(body: any) => {
                    this.body = body;
                    if (this.body) {
                        this.hookupFormFieldResets();
                    }
                }}>
                    {this.buildBody()}
                </Modal.Body>
                <Modal.Footer>
                    {this.buildActions()}
                </Modal.Footer>

            </Modal>
        )
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private buildBody(): ReactNode | undefined {
        if (this.state.style == ModalStyle.Input) {
            return (
                <div>
                    <label htmlFor="modal-input-box">
                        {this.state.message}
                    </label>
                    <input id="modal-input-box" className='full-width' onChange={e => this.setState({ value: e.target.value })} value={this.state.value} />
                </div>
            );
        }
        else if (this.state.style == ModalStyle.Memo) {
            return (
                <div>
                    <label htmlFor="modal-textarea">
                        {this.state.message}
                    </label>
                    <textarea id="modal-textarea" className='full-width' rows={6} onChange={e => this.setState({ value: e.target.value })} value={this.state.value} />
                </div>
            );
        }
        else if (this.state.style == ModalStyle.Form) {
            return this.state.form;
        }
        else {
            return (
                <div>
                    <pre className='full-width multiline-display'>{this.state.message}</pre>
                    {this.state.style != ModalStyle.Spin ? null : <LoadingWidget></LoadingWidget>}
                </div>
            );
        }
    }

    private getTitle(): string | undefined {
        if (this.state.title) {
            return this.state.title;
        }
        switch (this.state.style) {
            case undefined:
            case ModalStyle.Input:
            case ModalStyle.Memo:
            case ModalStyle.None: return undefined;
            default: return ModalStyle[this.state.style];
        }
    }

    private buildActions(): Array<any> {
        let result: Array<any> = [];
        if (this.state.actions == null || this.state.actions.length == 0) {
            result.push((
                <button className='btn btn-primary' key="defaultOK" onClick={async () => await this.close("OK")}>OK</button>
            ));
        }
        else {
            this.state.actions.map((action, index, actions) => {
                result.push((
                    <button type={index == 0 && this.state.form != null ? "submit" : "button"} className={'btn btn-' + (index == 0 && !this.state.noActionHighlight ? "primary" : "secondary")} key={index} onClick={async () => {
                        if (index == 0 && this.state.style == ModalStyle.Form) {
                            let hasErrors = await this.hasErrors();
                            if (hasErrors) {
                                this.setState({});
                                return;
                            }
                        }
                        await this.close(action);
                    }}>{action}</button>
                ));
            });
        }
        return result;
    }

    private hookupFormFieldResets() {
        var selector = $(this.body).find("form");
        var form = selector.length == 1 ? selector[0] : null;
        if (null != form) {
            $(form).find(".form-control").on("change", e => {
                if (e.target as HTMLInputElement) {
                    if ((e.target as HTMLInputElement).value != null && (e.target as HTMLInputElement).value.trim() != "") {
                        $(e.target).siblings("[val-msg-for='" + e.target.id + "']").addClass("validation-ok");
                    }
                }
            });
        }
    }

    private async hasErrors() {
        var selector = $(this.body).find("form");
        var form = selector.length == 1 ? selector[0] : null;
        if (null != form) {
            $(form).find(".validation-error").addClass("validation-ok");
            let isValid = form.checkValidity();
            let isCustomValid = true;
            if (this.state.onValidate) {
                isCustomValid = this.state.onValidate(form);
            }
            if (isValid && isCustomValid && this.state.onValidateAsync) {
                //only wait on the async validation if everything else is valid
                isCustomValid = await this.state.onValidateAsync(form);
            }
            if (!isValid || !isCustomValid) {
                $(form).find(":invalid").each((index, element) => {
                    // console.log((element as any).validity);
                    // console.log((element as any).validationMessage);
                    $(element)
                        .siblings("[val-msg-for='" + element.id + "']")
                        .removeClass("validation-ok")
                        .text((element as any).validationMessage);
                });
                return true;
            }
        }
        return false;
    }

    private closeTimeout: NodeJS.Timeout | undefined = undefined;
    private closeAnimationPromise: Promise<void> | undefined = undefined;

    private async close(result: string): Promise<void> {
        if (this.closeTimeout && this.closeAnimationPromise) {
            await this.closeAnimationPromise;
            return;
        }
        if (this.state.accept) {
            this.state.accept({ result, value: this.state.value });
        }
        //start the fade/slide out animation
        this.setState({hide: true});
        //clear the content later
        this.closeAnimationPromise = new Promise(resolve => {
            this.closeTimeout = setTimeout(() => {
                clearTimeout(this.closeTimeout!);
                this.closeTimeout = undefined;
                this.closeAnimationPromise = undefined;
                this.setState({
                    message: undefined,
                    result: undefined,
                    form: undefined,
                    value: "",
                    hide: false
                });
                resolve();
            }, 500);    
        });
        await this.closeAnimationPromise;
    }

    private isVisible() {
        return !this.state.hide && (this.state.message != null || this.state.form != null);
    }

    public static isShowing() {
        return this.instance.isVisible();
    }

    public static async getInput(message: string, title?: string, def?: string) {
        var result = await this.showDialog({
            message,
            title,
            actions: this.OkCancelActions,
            style: ModalStyle.Input,
            initialValue: def,
        });
        return result.result == "OK" ? result.value : undefined;
    }

    public static async getMemo(message: string, title?: string, def?: string, acceptAction?: string) {
        if (!acceptAction) {
            acceptAction = "OK";
        }
        var result = await this.showDialog({
            message,
            title,
            actions: [acceptAction, "CANCEL"],
            style: ModalStyle.Memo,
            initialValue: def,
        });
        return result.result == acceptAction ? result.value : undefined;
    }

    public static async showMessage(message: string, style?: ModalStyle, title?: string) {
        return await this.showDialog({
            message,
            title,
            actions: undefined,
            style: style ?? ModalStyle.Information,
        });
    }

    public static async showError(error: string) {
        return await this.showMessage(error, ModalStyle.Error);
    }

    public static AbortDownloadReason = "CANCELEDBYUSER";
    public static async showCancelableSpin(title: string, abortController: AbortController, message?: string) {
        let result = await this.showDialog({
            title,
            message: message ?? "",
            actions: ["CANCEL"],
            style: ModalStyle.Spin,
        });
        if (result && result.result == "CANCEL") {
            abortController.abort(this.AbortDownloadReason);
        }
    }

    public static async close() {
        await ApplicationModal.instance.close("");
    }

    public static async showForm(form: ReactNode, title: string, onValidate?: (form: any) => boolean,
        acceptAction?: string, cancelAction?: string, noAccept?: boolean, onValidateAsync?: (form: any) => Promise<boolean>) {
        if (!acceptAction) {
            acceptAction = "OK";
        }
        if (!cancelAction) {
            cancelAction = "CANCEL";
        }
        var result = await this.showDialog({
            form,
            title,
            style: ModalStyle.Form,
            actions: noAccept ? [cancelAction] : [acceptAction, cancelAction],
            onValidate: onValidate,
            onValidateAsync: onValidateAsync,
            noActionHighlight: noAccept
        });
        return result.result == acceptAction;
    }

    public static async confirm(message: string, yesNo?: boolean): Promise<boolean> {
        let actions = yesNo ? this.YesNoActions : this.OkCancelActions;
        var result = await this.showDialog({
            message,
            title: undefined,
            actions: actions,
            style: ModalStyle.None,
        });
        return result.result == actions[0];
    }

    public static async showDialog(props: IMessageBoxProperties): Promise<IModalResult> {
        var dialog = this.instance;
        // if a prior dialog has still not animated off then wait for it
        if (dialog.closeAnimationPromise) {
            await dialog.closeAnimationPromise;
        }
        var wait = new Promise((resolve, reject) => {
            dialog.setState({
                message: props.message,
                title: props.title,
                actions: props.actions,
                style: props.style,
                form: props.form,
                result: undefined,
                value: props.initialValue ?? "",
                accept: resolve,
                cancel: reject,
                onValidate: props.onValidate,
                onValidateAsync: props.onValidateAsync,
                noActionHighlight: props.noActionHighlight
            });
        });
        var waitedResult = await wait;
        return waitedResult as IModalResult;
    }
}

export default ApplicationModal;
