import React, { useEffect, useState } from 'react';
import './CreateNewsForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';

interface CreateNewsFormState extends vms.INews {
    organizations?: Array<vms.IOrganization>;
}

interface CreateNewsFormProps {
    isSuper: boolean;
}

class CreateNewsForm extends DialogFormBase<CreateNewsFormProps, CreateNewsFormState> {
    constructor(props: CreateNewsFormProps, state: CreateNewsFormState) {
        super(props);
        this.state = state;
    }

    private fetching: boolean = false;

    render() {
        return <div className='CreateNewsForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
        if (this.props.isSuper) {
            this.checkFetchOrganizations();
        }
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.props.isSuper
                    ? this.buildStandardSelect("organization-id", "Organization", this.state.organizationId!,
                        this.buildOptions(), e => this.setState({ organizationId: e.target.value }), true)
                    : null}
                {this.buildStandardInput("news-title", "Title", this.state.title, e => this.setState({ title: e.target.value }), true)}
                {this.buildStandardTextArea("news-body", "Body", this.state.body, 4, e => this.setState({ body: e.target.value }), true)}
                {this.buildStandardInput("news-link-label", "Link Label", this.state.linkLabel, e => this.setState({ linkLabel: e.target.value }), true)}
                {this.buildStandardInput("news-link-url", "Link URL", this.state.linkUrl, e => this.setState({ linkUrl: e.target.value }))}
            </form>
        );
    }

    private buildOptions() {
        let result: Array<IOptionData> = [];
        result.push(this.buildOption("", "Select an Organization"));
        result.push(this.buildOption("public", "Public"));
        result.push(this.buildOption("all", "All Organizations"));
        this.state.organizations?.forEach((item, index, items) => {
            result.push(this.buildOption(item.id, item.name ?? "MISSING NAME"));
        });
        return result;
    }

    private buildOption(value: string, text: string) {
        return { value, text };
    }

    private checkFetchOrganizations() {
        if (this.fetching) {
            return;
        }
        this.fetching = true;
        //todo: fetch a complete list of abbreviations (names, ids)
        OrganizationActions.enumerate("", 0)
            .then((result) => {
                this.setState({
                    organizations: result.records
                });
            });
    }
}

export default CreateNewsForm;
