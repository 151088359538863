import React, { useEffect, useState } from 'react';
import './ApplicationToast.css';
import { F } from '../Functions';

interface IToastMessage {
    id: number;
    message: string;
}

interface ApplicationToastState {
    messages: Array<IToastMessage>;
}

interface ApplicationToastProps {
}

class ApplicationToast extends React.Component<ApplicationToastProps, ApplicationToastState> {
    private static instance: ApplicationToast;
    private static nextMessageId: number;
    private static readonly autoCloseDelay: number = 3000;

    constructor(props: ApplicationToastProps, state: ApplicationToastState) {
        super(props);
        this.state = {
            messages: new Array<IToastMessage>()
        };
        ApplicationToast.instance = this;
        ApplicationToast.nextMessageId = 0;
    }

    render() {
        let doShow = this.state.messages.length > 0;
        return (
            <div
                className={'ApplicationToast container-fluid ' + (doShow ? "slide-in" : "slide-out")}
                // onMouseOver={e => { F.debugEvent(e); }}
                >
                <div className='row'>
                    <div className='col-2 col-md-4' style={{ backgroundColor: 'transparent' }}>

                    </div>
                    <div className='col-8 col-md-4'>
                        <div className='toast-panel' onClick={() => this.Hide()}>
                            <div className='header row no-gutters'>
                                <div className='col-10 info-cell'>Information</div>
                                <div className='col-2'>
                                    <div className='close-button float-right '>
                                        <i className='fa fa-x ' onClick={this.Hide.bind(this)}></i>
                                    </div>
                                </div>
                            </div>
                            <div className='body row no-gutters align-items-center'>
                                <div className='col-12'>
                                    {
                                        this.state.messages.map((value, index, values) => {
                                            return <div className='toast-message' key={index}>{value.message}</div>;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-2 col-md-4' style={{ backgroundColor: 'transparent' }}>

                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    public static Show(...messages: Array<string>) {
        for (let i =0; i<messages.length; i++) {
            let id = this.nextMessageId++;
            let message = messages[i];
            this.instance.state.messages.push({ id, message });
            if (0 != this.autoCloseDelay) {
                window.setTimeout(() => {
                    ApplicationToast.RemoveMessage(id);
                }, this.autoCloseDelay);
            }
        }
        this.Refresh();
    }

    private static RemoveMessage(id: number) {
        for (let i = 0; i < this.instance.state.messages.length; i++) {
            if (this.instance.state.messages[i].id == id) {
                this.instance.state.messages.splice(i, 1);
                this.Refresh();
                break;
            }
        }
    }

    private static Refresh() {
        this.instance.setState({});
    }

    private Hide() {
        this.setState({
            messages: []
        })
    }
}

export default ApplicationToast;
