import * as vms from "../ViewModels";
import { ControllerActions } from "./ControllerActions";
import {F} from "../Functions";
import ApplicationToast from "../ApplicationToast/ApplicationToast";
import { buildFullyQualifiedUrl } from './httpHelper';

export class OrganizationActions {

    private static buildOrganizationAction(action?: string, ...parts: Array<string>){
        return ControllerActions.buildUriAction("Organization", action, ...parts);
    }

    public static async enumerate(filter: string, startIndex: number): Promise<vms.IOrganizationResponse> {
        let action = this.buildOrganizationAction("Enumerate");
        var result = await ControllerActions.doPostWithToken<vms.IOrganizationResponse>(action, { filter, startIndex });
        return result;
    }

    public static async enumerateNames(): Promise<Array<vms.IOrganizationBrief>> {
        let action = this.buildOrganizationAction("EnumerateNames");
        var result = await ControllerActions.doGetWithToken<Array<vms.IOrganizationBrief>>(action);
        return result;
    }

    public static async get() {
        var action = this.buildOrganizationAction("Get");
        var result = await ControllerActions.doGetWithToken<vms.IOrganization>(action);
        return result;
    }

    public static async create(org: vms.IOrganization) {
        ApplicationToast.Show("Creating organization...");
        var action = this.buildOrganizationAction("Create");
        var result = await ControllerActions.doPostWithToken<vms.IOrganization>(action, org);
        if (result != null) {
            ApplicationToast.Show("Organization creation successful.");
        }
        return result != null;
    }

    public static async update(org: vms.IOrganization) {
        ApplicationToast.Show("Updating organization...");
        var action = this.buildOrganizationAction("Update");
        var result = await ControllerActions.doPostWithToken<vms.IOrganization>(action, org);
        if (result != null) {
            ApplicationToast.Show("Organization update successful.");
        }
        return result != null;
    }

    public static async notify(organizationId: string, message: string) {
        var action = this.buildOrganizationAction("Notify");
        var result = await ControllerActions.doPostWithToken<string>(action, { organizationId, message });
        return result;
    }

    public static async notifyNewScan(organizationId: string) {
        var action = this.buildOrganizationAction("NotifyNewScan");
        var result = await ControllerActions.doPostWithToken<string>(action, { organizationId });
        return result;
    }

    public static async getAvailableResources() {
        var action = this.buildOrganizationAction("GetAvailableResources");
        var result = await ControllerActions.doGetWithToken<vms.ICloudResourceItem>(action);
        return result;
    }

    //todo: we will need this when we are not debugging across domains
    public static buildViewResource(path: string) {
        var resource = this.buildOrganizationAction("ViewResource", path);
        return buildFullyQualifiedUrl(resource);
    }

    public static async getResource(path: string) {
        var action = this.buildOrganizationAction("GetResource?path=") + path;
        var result = await ControllerActions.doGetWithToken<Blob>(action);
        return result;
    }

    public static async resetResourceCache() {
        var action = this.buildOrganizationAction("RefreshResourceCache");
        var result = await ControllerActions.doGetWithToken(action);
        return result;
    }

    public static async getExportData(criteria: vms.IDataExportCriteria, signal: AbortSignal) {
        var action = this.buildOrganizationAction("GetExportData");
        var result = await ControllerActions.doPostWithToken<Blob>(action, criteria, signal);
        return result;
    }

    public static async getUsageReport(criteria: vms.IDataExportCriteria, signal: AbortSignal) {
        var action = this.buildOrganizationAction("GetUsageReport");
        var result = await ControllerActions.doPostWithToken<Blob>(action, criteria, signal);
        return result;
    }

    public static async getLogFiles(criteria: vms.IUsageLogDownloadRequest, signal: AbortSignal) {
        var action = this.buildOrganizationAction("GetLogFiles");
        var result = await ControllerActions.doPostWithToken<Blob>(action, criteria, signal);
        return result;
    }
}