import React, { useEffect, useState } from 'react';
import './UnsubscribeEmailView.css';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { param } from 'jquery';
import { F } from '../Functions';
import { PublicActions } from '../ControllerActionHelpers/PublicActions';
import { ModalDialog } from 'react-bootstrap';
import ApplicationModal from '../ApplicationModal/ApplicationModal';

interface UnsubscribeEmailViewState {
    didUnsubscribe?: boolean
}

interface UnsubscribeEmailViewProps {

}

class UnsubscribeEmailView extends React.Component<UnsubscribeEmailViewProps, UnsubscribeEmailViewState> {
    constructor(props: UnsubscribeEmailViewProps, state: UnsubscribeEmailViewState) {
        super(props);
        this.state = {}
    }

    render() {
        return <div className='UnsubscribeEmailView'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        if (this.state.didUnsubscribe) {
            return (<div>            
                <div className='unsubscribe-prompt'>
                    You have been unsubscribed. You may close this tab
                    or click Home to navigate to the home page.
                </div>
                <div className='rounded-button-blue' onClick={e => {
                    window.location.href = "/";
                }}>Home</div>
            </div>)            
        }
        return (<div>            
            <div className='unsubscribe-prompt'>Please click the Confirm button to unsubscribe from email notifications.</div>
            <div className='rounded-button-blue' onClick={async e => {
                const url = new URL(window.location.href);
                const params = new URLSearchParams(url.search);
                const email = params.get("email");
                if (F.isNullOrWhitespace(email)) {
                    ApplicationModal.showError("The email address is missing from the URL. To unsubscribe, please click the unsubscribe link sent via email.");
                    return;
                }
                const otp = params.get("otp");
                if (F.isNullOrWhitespace(otp)) {
                    ApplicationModal.showError("The one time password is missing from the URL. To unsubscribe, please click the unsubscribe link sent via email.");
                    return;
                }
                await PublicActions
                    .unsubscribeFromEmail(email!, otp!)
                    .then(async value => {
                        if (value == 1) {
                            //the unsubscribe attempt succeeded
                            this.setState({didUnsubscribe: true});
                        }
                        else {
                            await ApplicationModal.showError("Failed to unsubscribe. Contact your organization's administrator for support.");
                        }
                    });
            }}>Confirm</div>
        </div>)
    }

    //http://localhost:3000/unsubscribe?email=david%2B59@oculogica.com&otp=E5m0oGqEnk2q8G3dvOYYCw%3D%3D
    public static isQueryPresent() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const email = params.get("email");
        const otp = params.get("otp");
        if (F.isNullOrWhitespace(email) || F.isNullOrWhitespace(otp))
            return false;
        return true;
    }
}

export default UnsubscribeEmailView;
