// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App/App';
// import reportWebVitals from './reportWebVitals';
// import { Auth0Provider } from '@auth0/auth0-react';
// // See https://www.npmjs.com/package/@auth0/auth0-react

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <Auth0Provider
//       domain="dev-v9lxtl7u.us.auth0.com"
//       clientId="87Xr1h1Fwu41quBd5utcSxGt6J2hkDsO"
//       audience='https://localhost:7144'
//       redirectUri={window.location.origin}   
//       //scope="openid name picture"
//     >
//       <App />
//     </Auth0Provider>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// ///////////reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
// See https://www.npmjs.com/package/@auth0/auth0-react
import { F } from './Functions';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

new Promise(async (resolve, reject) => {
  while (!window.domainConfig) {
    await F.delay(200);
  }
  root.render(
    <React.StrictMode>
      <Auth0Provider
        //domain="dev-v9lxtl7u.us.auth0.com"
        domain={window.domainConfig.authDomain}
        clientId={window.domainConfig.authClientId}
        audience={window.domainConfig.authAudience}
        redirectUri={window.location.origin}   
        //scope="openid name picture"
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
  resolve({});
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
///////////reportWebVitals();
