import * as vms from "../ViewModels";
import {ControllerActions} from './ControllerActions'
import ApplicationModal from "../ApplicationModal/ApplicationModal";
import ApplicationToast from "../ApplicationToast/ApplicationToast";

export class NewsActions {

    private static buildNewsAction(action: string, ...parts: Array<string>){
        return ControllerActions.buildUriAction("News", action, ...parts);
    }

    public static async getUnread() {
        var action = this.buildNewsAction("GetUnread");
        var result = await ControllerActions.doGetWithToken<Array<vms.INews>>(action);
        return result;
    }

    public static async markRead(newsId: string) {
        var action = this.buildNewsAction("MarkRead");
        var result = await ControllerActions.doPostWithToken<vms.INewsReceipt>(action, { newsId });
        return result;
    }

    public static async create(news: vms.INews) {
        ApplicationToast.Show("Creating news...");
        if (news.organizationId == "all") {
            news.organizationId = undefined;
        }
        var action = this.buildNewsAction("Create");
        var result = await ControllerActions.doPostWithToken<vms.INews>(action, news);
        if (result != null) {
            ApplicationToast.Show("News creation successful.");
        }
        return result != null;
    }

    // public static async getPublic() {
    //     var action = this.buildNewsAction("GetPublic");
    //     var result = await ControllerActions.doGetWithToken<Array<vms.INews>>(action);
    //     return result;
    // }
}