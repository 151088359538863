import React, { useEffect, useState } from 'react';
import './CreateDeviceForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';
import { F } from '../Functions';

interface CreateDeviceFormState extends vms.IDevice {
}

interface CreateDeviceFormProps {
    initialValues: vms.IDevice;
    isCreating: boolean;
    configuration?: vms.IWebsiteConfiguration;
}

class CreateDeviceForm extends DialogFormBase<CreateDeviceFormProps, CreateDeviceFormState> {
    constructor(props: CreateDeviceFormProps, state: CreateDeviceFormState) {
        super(props);
        this.state = F.clone(this.props.initialValues);
    }

    render() {
        return <div className='CreateDeviceForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.buildStandardInput("eyebox-device-id", "Device ID", this.state.eyeBOXDeviceID, e => this.setState({ eyeBOXDeviceID: e.target.value }), true, undefined, undefined, !this.props.isCreating)}
                {this.buildStandardSelect("model", "Model", this.state.model, this.buildModelOptions(), e => this.setState({ model: e.target.value }), true)}
                {this.buildStandardInput("serial-number", "Serial Number", this.state.serialNumber, e => this.setState({ serialNumber: e.target.value }), true)}
                {this.buildStandardInput("software-version", "Software", this.state.softwareVersion, e => this.setState({ softwareVersion: e.target.value }), true, () => false, undefined, true)}
                {this.buildStandardDatePicker("placed-in-service", "Service Start Date", this.state.placedInService, e => this.setPlacedInService(e.target.value), true, () => false)}
                {this.buildStandardDatePicker("license-expiration", "License Expiration Date", this.state.licenseExpiration, e => this.setLicenseExpiration(e.target.value), true, () => false)}
            </form>
        );
    }

    private setPlacedInService(s: string) {
        if (!F.isNullOrWhitespace(s)) {
            this.setState({placedInService:new Date(s)})
        }
        else {
            this.setState({placedInService: undefined});
        }
    }

    private setLicenseExpiration(s: string) {
        if (!F.isNullOrWhitespace(s)) {
            this.setState({licenseExpiration:new Date(s)})
        }
        else {
            this.setState({licenseExpiration: undefined});
        }
    }

    private buildModelOptions(): Array<IOptionData> {
        let result: Array<IOptionData> = [];
        result.push(this.buildStandardOption("", "--- Select ---"));
        this.props.configuration?.deviceModels?.map((deviceModel, index, deviceModels) => {
            result.push(this.buildStandardOption(deviceModel.name));
        });
        return result;
    }
}

export default CreateDeviceForm;
