import { info } from "console";
import ApplicationModal from "../ApplicationModal/ApplicationModal";
import {F} from "../Functions";
import { IApplicationError } from "../ViewModels";

function getServerBaseURL(): string {
    let hostname = window.location.hostname;
    //console.log(hostname);
    if (hostname == "localhost") {
        //assume different client and server sites
        return 'https://localhost:7144';
    }
    //assume same client and server site
    return "https://" + hostname;
}

function unknown<T>() {
    var bad: unknown = null;
    return bad as T;
}

function validateApplicationError(obj: any): IApplicationError | undefined {
    let temp: IApplicationError = obj;
    if (temp && temp.isApplicationError === true) {
        //let result: IApplicationError = new {...obj};
        //return result;
        return temp;
    }
    return undefined;
}

async function decodeResponse<T>(response: Response): Promise<T> {
    //redirect home if the user is unauthorized
    if (response.status == 401) {
        if (window.location.pathname != "/") {
            window.location.href = "/";
            return unknown<T>();
        }        
    }
    if (response.status != 200) {
        let errorResponse = await response.text();
        let errorMessage = undefined;
        try {
            let result: any = JSON.parse(errorResponse);
        let applicationError: IApplicationError | undefined = validateApplicationError(result);
        if (applicationError) {
            if (applicationError.isUserError) {
                //we actually want to display these server-generated errors to the user
                await ApplicationModal.showError(applicationError.message);
            }
            else {
                console.log(applicationError.message);
                await ApplicationModal.showError("An error occurred while performing the operation.");
            }
            return unknown<T>();
        }
            if (result.message && result.exceptionType) {
                //todo: maybe have different handling for different exceptions
                errorMessage = result.message;
                //console.log(result.message);
                //return unknown<T>();
            }
        }
        catch {

        }
        if (errorMessage != undefined) {
            throw new Error(errorMessage);
        }
        //console.log(errorResponse);
        return unknown<T>();
    }
    if (response.headers.get("content-type")?.indexOf("application/json") != -1) {
        var json = await response.text();
        //console.log(json);
        var result: any = JSON.parse(json);
        if (result.Error) {
            console.log(result.Error);
            return unknown<T>();
        }
        //console.log(result);
        return result as T;
    }
    else {
        var blob = await response.blob();
        return blob as unknown as T;
    }
}

export async function doGet<T>(resource: string, token: string): Promise<T> {
    LastResponse.value = undefined;
    const response = await fetch(buildFullyQualifiedUrl(resource), {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    });
    try {
        LastResponse.value = response;
        var result = await decodeResponse(response);
        return result as T;
    }
    catch (e: any) {
        throw new Error("Error accessing resource '" + resource + "'", { cause: e})
    }
}

const requestMap = new Map();

function generateRequestKey(resource: string, obj: any) {
    let result = resource;
    if (obj) {
        for (let key in obj) {
            result += ":" + key + "=";
            let val = obj[key];
            if (!F.isNullOrWhitespace(val)) {
                result += val.trim();
            }
            else {
                result += val;
            }
        }
        //result = result + ":" + JSON.stringify(obj);
    }
    return result;
}

export async function doPost<T>(resource: string, token: string, obj: any, signal?: AbortSignal): Promise<T> {
    LastResponse.value = undefined;
    let requestKey = generateRequestKey(resource, obj);
    let response: Response;
    if (requestMap.has(requestKey)) {
        //console.log("awaiting prior request");
        let priorPromise = requestMap.get(requestKey);
        response = await priorPromise;
    }
    else {
        let requestPromise = fetch(buildFullyQualifiedUrl(resource), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(obj),
            signal
        });
        requestMap.set(requestKey, requestPromise);
        try {
            response = await requestPromise;
        }
        finally {
            requestMap.delete(requestKey);
        }
    }
    try {
        let clonedResponse = response.clone();
        LastResponse.value = clonedResponse;
        var result = await decodeResponse(clonedResponse);
        return result as T;
    }
    catch (e: any) {
        throw new Error("Error accessing resource '" + resource + "'", { cause: e.message })
    }
}

export class LastResponse {
    static value: Response | undefined;
}

export function buildFullyQualifiedUrl(resource: string): string {
    return getServerBaseURL() + '/' + resource;
}

export function getLastFilename(): string {
    if (!LastResponse.value) {
        return "";
    }
    const contentDisposition = LastResponse.value.headers.get('content-disposition');
    if (contentDisposition) {
        var fields = contentDisposition.split(";");
        if (fields.length == 0) {
            return "";
        }
        for (let field of fields) {
            const filenameLabel = "filename=";
            let filenameStart = field.indexOf(filenameLabel);
            if (filenameStart != -1) {
                let result = field.substring(filenameStart + filenameLabel.length).trimEnd();
                return result;
            }
        }
    }
    return "";
}
