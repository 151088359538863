/*
    PageActions is a common location shared by the App, which shows the actions
    in its menu, and the child pages, which specify which actions should be displayed.
*/
export class PageActions {
    private static pageActions: {[label: string]: { () : void; } } = {};
    private static onChangeListeners: Array<{(): void}> = [];
    private static updating: boolean = false;

    private static NotifyListeners(): void {
        if (this.updating) {
            return;
        }
        for (var listener of this.onChangeListeners) {
            //console.log("Calling onChangeListener");
            listener();
        }
    }

    public static Clear() {
        this.pageActions = {};
        this.NotifyListeners();
    }

    public static Add(label: string, action: {() : void}) {
        this.pageActions[label] = action;
        this.NotifyListeners();
    }

    public static Enumerate() {
        return this.pageActions;
    }

    public static Listen(handler: {() : void}): {(): void} {
        this.onChangeListeners.push(handler);
        //return an unlisten method
        return () => {
            let handlerIndex = this.onChangeListeners.indexOf(handler);
            if (handlerIndex != -1) {
                this.onChangeListeners.splice(handlerIndex, 1);
            }
        };
    }

    public static BeginUpdate(): void {
        this.updating = true;
    }

    public static EndUpdate(): void {
        this.updating = false;
        this.NotifyListeners();
    }
}
