import React, { useEffect, useState } from 'react';
import './ConfigureSiteForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase from '../DialogFormBase/DialogFormBase';
import { F } from '../Functions';
import ApplicationToast from '../ApplicationToast/ApplicationToast';

interface ConfigureSiteFormState extends vms.IWebsiteConfiguration {
    newMessage?: string,
    mode: ConfigurationMode
}

interface ConfigureSiteFormProps {
    initialConfig: vms.IWebsiteConfiguration
}

enum ConfigurationMode {
    Global,
    Welcome,
    DeviceModel,
}

class ConfigureSiteForm extends DialogFormBase<ConfigureSiteFormProps, ConfigureSiteFormState> {
    constructor(props: ConfigureSiteFormProps, state: ConfigureSiteFormState) {
        super(props);
        var deviceModels = props.initialConfig.deviceModels;
        if (deviceModels.length == 0) {
            deviceModels = this.getDefaultDeviceModels();
        }
        this.state = {
            id: props.initialConfig.id,
            domain: props.initialConfig.domain,
            appName: props.initialConfig.appName,
            primaryMessage: props.initialConfig.primaryMessage,
            secondaryMessages: F.clone(props.initialConfig.secondaryMessages),
            mode: ConfigurationMode.Global,
            deviceModels
        };
    }

    render() {
        return <div className='ConfigureSiteForm'>
            {this.buildTitleRow()}
            {this.BuildContent()}
        </div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private buildTitleRow() {
        return <div className='row title-row full-width middle'>
            <div
                className={'col mode' + this.getSelectedClass(ConfigurationMode.Global)}
                onClick={e => this.setMode(ConfigurationMode.Global)}>
                <label>Site-Wide</label>
            </div>
            <div
                className={'col mode' + this.getSelectedClass(ConfigurationMode.Welcome)}
                onClick={e => this.setMode(ConfigurationMode.Welcome)}>
                <label>Welcome Page</label>
            </div>
            <div
                className={'col mode' + this.getSelectedClass(ConfigurationMode.DeviceModel)}
                onClick={e => this.setMode(ConfigurationMode.DeviceModel)}>
                <label>Device Model</label>
            </div>
        </div>
    }

    private BuildContent() {
        switch (this.state.mode) {
            case ConfigurationMode.Global: return this.buildGlobalConfiguration();
            case ConfigurationMode.Welcome: return this.buildWelcomeConfiguration();
            case ConfigurationMode.DeviceModel: return this.buildDeviceModelConfiguration();
            default: return <span>{"Unsupported mode: " + this.state.mode}</span>;
        }
    }

    private setMode(mode: ConfigurationMode) {
        this.setState({mode});
    }

    private getSelectedClass(mode: ConfigurationMode) {
        if (this.state.mode == mode) {
            return " selected";
        }
        return "";
    }

    private buildDeviceModelConfiguration() {
        return (            
            <form noValidate className='config-panel'>
                {this.buildDeviceModelInputs()}
                <div className='add-model-button full-width' onClick={e => this.addModel()}>
                    Add Model
                </div>
            </form>
        );
    }

    private buildDeviceModelInputs() {
        var result: Array<any> = [];
        this.state.deviceModels.map((deviceModel, index, deviceModels) => {
            result.push(<div key={deviceModels.length + "-" + index} className='device-model'>
                <div className='row no-gutters'>
                    <div className='col-10'>{F.isNullOrWhitespace(deviceModel.name) ? "Enter Model Name..." : deviceModel.name }</div>
                    <div className='col-2 up-down-buttons'>
                        { index > 0 ? <a className="rounded-button-small fa fa-angle-up" onClick={() => this.moveModelUp(index)} ></a> : <span className='dummy-button'/>}
                        { index < deviceModels.length - 1 ? <a className="rounded-button-small fa fa-angle-down" onClick={() => this.moveModelDown(index)} ></a> : <span className='dummy-button'/>}                        
                    </div>
                </div>
                {this.buildStandardInput("deviceModel_" + index, "Model Name", deviceModel.name, e => {
                    this.updateDeviceModelName(e, index);
                }, true, () => false)}
                {this.buildStandardInput("simpleModel_" + index, "Simple Model", deviceModel.simpleModel, e => {
                    this.updateDeviceSimpleModel(e, index);
                }, true, () => false)}
                {this.buildStandardInput("imageFilename_" + index, "Image Filename", deviceModel.imageFilename, e => {
                    this.updateDeviceImageFilename(e, index);
                }, false, () => false)}                
            </div>);
        });
        return result;
    }

    private updateDeviceModelName(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        var models: Array<vms.IDeviceModelInfo> = F.clone(this.state.deviceModels);
        models[index].name = e.target.value;
        this.setState({deviceModels: models});
    }

    private updateDeviceSimpleModel(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        var models: Array<vms.IDeviceModelInfo> = F.clone(this.state.deviceModels);
        models[index].simpleModel = e.target.value;
        this.setState({deviceModels: models});
    }

    private updateDeviceImageFilename(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        var models: Array<vms.IDeviceModelInfo> = F.clone(this.state.deviceModels);
        models[index].imageFilename = e.target.value;
        this.setState({deviceModels: models});
    }

    private buildGlobalConfiguration() {
        return (            
            <form noValidate className='config-panel'>
                {this.buildStandardInput("domain", "Domain", this.state.domain, e => { this.setState({domain: e.target.value}) }, true, undefined, undefined, true)}
                {this.buildStandardInput("appName", "App Name", this.state.appName, e => {this.setState({appName: e.target.value})}, true, )}
            </form>
        );
    }

    private buildWelcomeConfiguration() {
        return (            
            <form noValidate className='config-panel'>
                {this.buildStandardInput("primary-message", "Primary Message", this.state.primaryMessage, e => { this.setState({primaryMessage: e.target.value}) }, true)}
                <div className=''>
                <label>Secondary Messages</label>
                <div>(Clearing a message will cause its removal upon Save)</div>
                {this.state.secondaryMessages?.map((value, index, msgs) => {
                  return (<div key={msgs.length + "-" + index} className='row'>
                    <div className='col-10'>
                        <textarea value={value} onChange={e => this.updateItemText(index, e.target.value)} rows={2} cols={45}></textarea>
                    </div>
                    <div className='col-2 up-down-buttons'>
                        { index > 0 ? <a className="rounded-button-small fa fa-angle-up" onClick={() => this.moveMessageUp(index)} ></a> : <span className='dummy-button'/>}
                        { index < msgs.length - 1 ? <a className="rounded-button-small fa fa-angle-down" onClick={() => this.moveMessageDown(index)} ></a> : <span className='dummy-button'/>}                        
                    </div>
                  </div>);
                })}
                {this.buildAddSecondaryMessage()}

                </div>
            </form>
        );
    }

    private buildAddSecondaryMessage() {
        return <div className='row'>
            <div className='col-10'>
                <textarea value={this.state.newMessage} onChange={e => this.setState({newMessage: e.target.value})} rows={2} cols={45}></textarea>
            </div>
            <div className='col-2 up-down-buttons'>
                <a className="rounded-button-small" onClick={this.addSecondaryMessage.bind(this)}>Add</a>
            </div>
        </div>
    }

    private updateItemText(index: number, val: string) {
        var msgs = F.clone(this.state.secondaryMessages);
        msgs[index] = val;
        this.setState({secondaryMessages: msgs});
    }

    private async addSecondaryMessage() {
        if (F.isNullOrWhitespace(this.state.newMessage)) {
            return;
        }
        var msgs = F.clone(this.state.secondaryMessages);
        msgs.push(this.state.newMessage);
        this.setState({secondaryMessages: msgs, newMessage: ""});
    }

    private moveMessageUp(index: number) {
        if (index < 1) {
            //this should not occur based on the UI construction code
            return;
        }
        this.swapMessagePositions(index, index - 1);
    }

    private moveMessageDown(index: number) {
        if (index >= this.state.secondaryMessages.length - 1) {
            //this should not occur based on the UI construction code
            return;
        }
        this.swapMessagePositions(index, index + 1);
    }

    private swapMessagePositions(indexA: number, indexB: number) {
        var msgs = F.clone(this.state.secondaryMessages);
        var a = msgs[indexA];
        msgs[indexA] = msgs[indexB];
        msgs[indexB] = a;
        this.setState({secondaryMessages: msgs});
    }

    private getDefaultDeviceModels(): Array<vms.IDeviceModelInfo> {
        var result: Array<vms.IDeviceModelInfo> = [];
        result.push({ name: "DMR-0001 (OCL 01)", simpleModel: "dmr-0001", imageFilename: ""});
        result.push({ name: "DMR-0002 (OCL 02)", simpleModel: "dmr-0002", imageFilename: ""});
        result.push({ name: "DMR-0003 (OCL 02.5)", simpleModel: "dmr-0003", imageFilename: ""});
        result.push({ name: "DMR-0004 (EBX-4)", simpleModel: "dmr-0004", imageFilename: "ebx-4.png"});
        result.push({ name: "DMR-0005 (OcuPro/EBLens)", simpleModel: "dmr-0005", imageFilename: ""});
        result.push({ name: "VISOR rev2", simpleModel: "visor-rev2", imageFilename: ""});
        result.push({ name: "VISOR rev3", simpleModel: "visor-rev3", imageFilename: ""});
        result.push({ name: "VISOR rev4", simpleModel: "visor-rev4", imageFilename: ""});
        result.push({ name: "EBPROTO-4a", simpleModel: "ebproto-4a", imageFilename: ""});
        result.push({ name: "QNX-based Research Device", simpleModel: "qnx", imageFilename: ""});
        result.push({ name: "Other", simpleModel: "other", imageFilename: ""});
        return result;
    }

    private moveModelUp(index: number) {
        if (index < 1) {
            //this should not occur based on the UI construction code
            return;
        }
        this.swapModelPositions(index, index - 1);
    }

    private moveModelDown(index: number) {
        if (index >= this.state.deviceModels.length - 1) {
            //this should not occur based on the UI construction code
            return;
        }
        this.swapModelPositions(index, index + 1);
    }

    private swapModelPositions(indexA: number, indexB: number) {
        var models = F.clone(this.state.deviceModels);
        var a = models[indexA];
        models[indexA] = models[indexB];
        models[indexB] = a;
        this.setState({deviceModels: models});
    }

    private addModel() {
        var models: Array<vms.IDeviceModelInfo> = F.clone(this.state.deviceModels);
        models.push({name: "", simpleModel: "", imageFilename: ""});
        this.setState({deviceModels: models});
    }
}

export default ConfigureSiteForm;
