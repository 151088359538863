import React, { useEffect, useState } from 'react';
import './PlayOrDownloadVideoForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase from '../DialogFormBase/DialogFormBase';
import { ModalDialog } from 'react-bootstrap';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import { F } from '../Functions';

interface PlayOrDownloadVideoFormState {
}

interface PlayOrDownloadVideoFormProps {
    videoUrl: string;
    videoFilename: string;
    reportID: string;
    reportDateTime: Date;
    videoSizeBytes: number;
}

class PlayOrDownloadVideoForm extends DialogFormBase<PlayOrDownloadVideoFormProps, PlayOrDownloadVideoFormState> {
    constructor(props: PlayOrDownloadVideoFormProps, state: PlayOrDownloadVideoFormState) {
        super(props);
        this.state = state;
    }

    render() {
        return <div className='PlayOrDownloadVideoForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (<div>
            <div>Report ID: {this.props.reportID}</div>
            <div>{F.getDateTimeString(this.props.reportDateTime)}</div>
            <a className='btn btn-primary' href={this.props.videoUrl} target='_blank' onClick={e => this.playOrDownloadClicked(5 * 60000)}>PLAY VIDEO</a>

            <div>
                <a className='download-link' href={this.props.videoUrl} target='_blank' download={this.props.videoFilename} onClick={e => this.playOrDownloadClicked(10000)}>
                    Download video
                </a>
                &nbsp;
                ({(this.props.videoSizeBytes / 1024 / 1024).toFixed(0)} MB)
            </div>
        </div>)
    }

    private playOrDownloadClicked(urlTimeoutMs: number) {
        setTimeout(() => URL.revokeObjectURL(this.props.videoUrl), urlTimeoutMs);
        ApplicationModal.close();
    }
}

export default PlayOrDownloadVideoForm;
