import React, { useEffect, useState } from 'react';
import './OrganizationDevices.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DeviceCard from '../DeviceCard/DeviceCard';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import { UserActions } from '../ControllerActionHelpers/UserActions';
import CreateUserForm from '../CreateUserForm/CreateUserForm';
import CreateDeviceForm from '../CreateDeviceForm/CreateDeviceForm';
import { DeviceActions } from '../ControllerActionHelpers/DeviceActions';

interface OrganizationDevicesState {
    org: vms.IOrganization;
    configuration?: vms.IWebsiteConfiguration;
}

interface OrganizationDevicesProps  {
    org: vms.IOrganization;
    userInfo: vms.EyeBOXUser;
    onDevicesUpdated: () => void;
    configuration?: vms.IWebsiteConfiguration;
}

class OrganizationDevices extends React.Component<OrganizationDevicesProps, OrganizationDevicesState> {
    constructor(props: OrganizationDevicesProps, state: OrganizationDevicesState) {
        super(props);
        this.state = {
            org: props.org,
            configuration: props.configuration
        };
    }

    render() {
        return <div className='OrganizationDevices'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <div>
                <div className='row'>
                    <div className='col-4'>
                        <ul className='primary-item-list'>
                            <li>Devices</li>
                        </ul>
                    </div>
                    <div className='col-8 right'>
                        {this.canAddDevices()
                            ? <a className='rounded-button-blue' onClick={e => this.addDevice()}>ADD DEVICE</a>
                            : null
                        }
                    </div>
                </div>
                {this.buildDeviceCards()}
            </div>)
    }

    private canAddDevices() {
        return this.props.userInfo.HasRole(vms.EyeBOXUserRole.Super);
    }

    private getOrderedDevices(): Array<vms.IDevice> {
        var allDevices: Array<vms.IDevice> = [];

        this.state.org?.devices?.map((device, index, devices) => {
            allDevices.push(device);
        });

        //now sort the scans
        let result = allDevices.sort((a, b) => {
            return (a.serialNumber?.toLowerCase()??"").localeCompare(b.serialNumber?.toLowerCase()??"")
        });

        return result;
    }

    private buildDeviceCards(): Array<any> {
        var result: Array<any> = [];        
        this.getOrderedDevices().map((device, index, devices) => {
            result.push(<DeviceCard
                     key={devices.length + "-" + index}
                     device={device}
                     currentUser={this.props.userInfo}
                     onUpdated={() => {
                        this.props.onDevicesUpdated();
                        this.refreshOrganization();
                     }}
                     configuration={this.state.configuration}
                   />)
        });
        if (result.length == 0) {
            result.push(<span key='nousers'>No devices found</span>);
        }
        return result;
    }

    private async addDevice() {
        if (!this.canAddDevices()) {
            ApplicationToast.Show("You are not authorized to add devices.");
            return;
        }
        var form: any;
        var add = await ApplicationModal.showForm(<CreateDeviceForm
            ref={e => { form = e ?? form; }}
            //isSuper={this.props.userInfo.HasRole(vms.EyeBOXUserRole.Super)}
            initialValues={{organizationId: this.state.org.id}}
            isCreating={true}
            configuration={this.props.configuration}
            //isSelf={false}
        />, "Add Device", undefined, "Save");
        if (add) {
            await DeviceActions.add(form.state)
                .then(async addedDevice => {
                    if (addedDevice) {
                        this.props.onDevicesUpdated();
                        await this.refreshOrganization();
                    }
                })
                .catch(ControllerActions.reportError);
        }
    }

    private async refreshOrganization() {
        var org = await OrganizationActions.get();
        if (org) {
            this.setState({org});
        }
    }
}

export default OrganizationDevices;
