import * as vms from "../ViewModels";
import { ControllerActions } from "./ControllerActions";
import {F} from "../Functions";
import ApplicationToast from "../ApplicationToast/ApplicationToast";

export class PatientActions {

    private static buildPatientAction(action?: string, ...parts: Array<string>){
        return ControllerActions.buildUriAction("Patient", action, ...parts);
    }

    public static async enumerate(filter: string, startIndex: number): Promise<vms.IPatientResponse> {
        //console.log("getPatients", filter, pageIndex, pageSize);
        var action = this.buildPatientAction("Enumerate");
        var result = await ControllerActions.doPostWithToken<vms.IPatientResponse>(action, { filter, startIndex });
        for (var p of result.records) {
            if (p.age == undefined) {
                //we have the value for ocupro, need to compute for eyebox
                p.age = F.getAge(p.dob);
            }
        }
        return result;
    }

    public static async getPatient(patientId: string): Promise<vms.IPatient> {
        var action = this.buildPatientAction("GetPatient", patientId);
        var result = await ControllerActions.doGetWithToken<vms.IPatient>(action);
        if (result.age == undefined) {
            result.age = F.getAge(result.dob);
        }
        return result;
    }

    public static async getScanFiles(patientId: string, scanIds: Array<string>, contentTypes: Array<vms.ContentTypes>, signal: AbortSignal): Promise<Blob> {
        var action = this.buildPatientAction("GetScanFiles");
        var result = await ControllerActions.doPostWithToken<Blob>(action, { patientId, scanIds, contentTypes }, signal);
        //there will be either 0 or 1 file returned
        //if multiple files/types are requested, they will be packaged in a zip archive
        //if a single file is requested, it will be downloaded as-is
        return result;
    }

    public static async getScanUrl(patientId: string, scanIds: Array<string>, contentTypes: Array<vms.ContentTypes>) {
        var action = this.buildPatientAction("GetScanUrl");
        var result = await ControllerActions.doPostWithToken<vms.IFileResponse>(action, { patientId, scanIds, contentTypes });
        return result;
    }

    public static async updateScanComment(patientId: string, scanId: string, comment: vms.IScanComment): Promise<vms.IScanComment> {
        var action = this.buildPatientAction("UpdateScanComment");
        var result = await ControllerActions.doPostWithToken<vms.IScanComment>(action, { patientId, scanId, comment });
        return result;
    }

    public static async deleteScanComment(patientId: string, scanId: string, commentId: string): Promise<boolean> {
        var action = this.buildPatientAction("DeleteScanComment");
        var result = await ControllerActions.doPostWithToken<boolean>(action, { patientId, scanId, commentId });
        return result;
    }

    public static async getScanArchive(patientId: string, scanId: string, signal: AbortSignal): Promise<Blob> {
        var action = this.buildPatientAction("GetScanArchive");
        var scanIds = [scanId] ;
        var result = await ControllerActions.doPostWithToken<Blob>(action, {patientId, scanIds}, signal);
        return result;
    }
}