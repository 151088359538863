import * as vms from "../ViewModels";
import { ControllerActions } from "./ControllerActions";
import ApplicationToast from "../ApplicationToast/ApplicationToast";
import ApplicationModal from "../ApplicationModal/ApplicationModal";

export class DeviceActions {

    private static buildDeviceAction(action: string, ...parts: Array<string>){
        return ControllerActions.buildUriAction("Device", action, ...parts);
    }

    public static async add(device: vms.IDevice) {
        ApplicationToast.Show("Adding " + device.eyeBOXDeviceID + " to the organization...");
        var action = this.buildDeviceAction("Add");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, device);
        if (result != null) {
            ApplicationToast.Show("Device assignment successful.");
        }
        return result != null;
    }

    public static async update(device: vms.IDevice) {
        ApplicationToast.Show("Updating device '" + device.eyeBOXDeviceID + "'...");
        var action = this.buildDeviceAction("Update");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, device);
        if (result != null) {
            ApplicationToast.Show("Device update successful.");
        }
        return result != null;
    }

    public static async delete(device: vms.IDevice) {
        var confirmed = await ApplicationModal.confirm("Delete device '" + device.eyeBOXDeviceID + "'?");
        if (!confirmed) {
            return false;
        }
        ApplicationToast.Show("Deleting device '" + device.eyeBOXDeviceID + "'...");
        var action = this.buildDeviceAction("Delete");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, device);
        if (result != null) {
            ApplicationToast.Show("Device deletion successful.");
        }
        return result != null;
    }

    public static async move(deviceId: string, organizationId: string) {
        var action = this.buildDeviceAction("Move");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, {
             eyeBOXDeviceID: deviceId,
             newOrganizationId: organizationId 
            });
        return result;
    }

}