import React, { useEffect, useState } from 'react';
import './PasswordEntryForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase from '../DialogFormBase/DialogFormBase';
import { F } from '../Functions';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { UserActions } from '../ControllerActionHelpers/UserActions';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import { ModalDialog } from 'react-bootstrap';

interface PasswordEntryFormState {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    updating: boolean;
    invalidTokenMessage?: string;
}

interface PasswordEntryFormProps {
    email: string;
    requireCurrentPassword?: boolean;
    token?: string;
}

class PasswordEntryForm extends DialogFormBase<PasswordEntryFormProps, PasswordEntryFormState> {
    private static instance: PasswordEntryForm | undefined;

    private static tokenTestPromise: Promise<vms.IUpdatePasswordResponse> | undefined;

    private async testToken() {
        //console.log(`testToken(${this.props.token})`);
        if (PasswordEntryForm.tokenTestPromise) {
            //console.log(`awaiting existing call`);
            //await PasswordEntryForm.tokenTestPromise;
            return;
        }
        PasswordEntryForm.tokenTestPromise = new Promise<vms.IUpdatePasswordResponse>(async (resolve, reject) => {
            try {
                const result = await UserActions.validatePasswordToken(this.props.email, this.props.token!);
                PasswordEntryForm.tokenTestPromise = undefined;
                resolve(result);
            }
            catch (error) {
                PasswordEntryForm.tokenTestPromise = undefined;
                reject(error);
            }
        });
        var response = await PasswordEntryForm.tokenTestPromise;
        if (!response) {
            this.setState({
                invalidTokenMessage: "The password reset service seems to be unavailable. Please try again later.",
                updating: false
            });
        }
        else if (!response.success) {
            this.setState({
                invalidTokenMessage: response.message,
                updating: false
            });
        }
        else {
            this.setState({
                updating: false
            });
        }
    }

    constructor(props: PasswordEntryFormProps, state: PasswordEntryFormState) {
        super(props);
        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            updating: !F.isNullOrWhitespace(this.props.token)
        }
    }

    render() {
        return <div className='PasswordEntryForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
        PasswordEntryForm.instance = this;
        if (this.props.token) {
            this.testToken();
        }
    }

    componentWillUnmount() {
        PasswordEntryForm.instance = undefined;        
    }

    private BuildContent() {
        return F.isNullOrWhitespace(this.state.invalidTokenMessage)
        ? (
            this.state.updating
            ? <LoadingWidget />
            : (<form noValidate>
            { this.buildStandardInput('email', 'Email', this.props.email, e => { }, true, () => true, undefined, true) }
            {
                this.props.requireCurrentPassword
                ? this.buildStandardInput('currentPassword', 'Current Password', this.state.currentPassword, e => { 
                        this.setState({currentPassword: e.target.value})
                    }, true, () => true, undefined, false, undefined, true)
                : null
            }
            { this.buildStandardInput('newPassword', 'New Password', this.state.newPassword, e => { 
                    this.setState({newPassword: e.target.value}, () => {
                        F.resetValidation(e.target);                    
                    })
                 }, true, () => true, undefined, false, undefined, true, true) }
            { this.buildStandardInput('confirmPassword', 'Confirm Password', this.state.confirmPassword, e => { 
                    this.setState({confirmPassword: e.target.value}, () => {
                        F.resetValidation(e.target);
                    })
                 }, false, () => true, undefined, false, undefined, true) }
            </form>
            )
        )
        : (
            <form noValidate>
                <div>{this.state.invalidTokenMessage}</div>
                <div>To request a new link via email, click the OK button.</div>
            </form>
        )
    }

    public static async OnValidateAsync(form: any): Promise<boolean> {   
        //if the user clicks OK while there is an invalidTokenMessage then
        //they want to request a new password reset token     
        if (PasswordEntryForm.instance?.state.invalidTokenMessage) {
            let email = PasswordEntryForm.instance?.props.email;
            try {
                await UserActions.requestPasswordReset(email);
            }
            catch {

            }
            return true;
        }
        let newValidPassword = this.checkPasswordValid(form, "newPassword");
        let passwordConfirmed = this.checkPasswordConfirmed(form, "newPassword", "confirmPassword");
        if (!F.isNullOrWhitespace(newValidPassword) && passwordConfirmed) {
            let emailBox = $(form).find("#email")[0] as HTMLInputElement;
            let email = emailBox.value;

            let currentPasswordSelector = $(form).find("#currentPassword");
            if (currentPasswordSelector.length == 1) {
                let currentPasswordBox = currentPasswordSelector[0] as HTMLInputElement;
                let currentPassword = currentPasswordBox.value;
                let updateResult = await this.updatePassword(email, currentPassword, newValidPassword!);
                if (updateResult && updateResult.success) {                    
                    return true;
                }
                if (F.isNullOrWhitespace(updateResult?.message)) {
                    ApplicationToast.Show("Failed to update the user password.");
                }
                else {
                    ApplicationToast.Show(updateResult.message);
                }
                return false;
            }
            else {
                let updateResult = await this.resetPassword(email, this.instance?.props.token!, newValidPassword!);
                if (updateResult && updateResult.success) {
                    return true;
                }
                if (F.isNullOrWhitespace(updateResult?.message)) {
                    ApplicationToast.Show("Failed to reset the user password.");
                }
                else {
                    ApplicationToast.Show(updateResult.message);
                }
                return false;
            }
        }
        else {
            return false;
        }
    }

    private static async updatePassword(email: string, currentPassword: string, newPassword: string): Promise<vms.IUpdatePasswordResponse> {
        try {
            this.instance?.setState({updating: true});
            let request: vms.IUpdatePasswordRequest = {
                email,
                currentPassword,
                newPassword,
            };
            let response = await UserActions.updatePassword(request);
            return response;
        }
        finally
        {
            this.instance?.setState({updating: false});
        }
    }

    private static async resetPassword(email: string, token: string, newPassword: string) {
        try {
            this.instance?.setState({updating: true});
            let request: vms.IUpdatePasswordRequest = {
                email,
                resetToken: token,
                newPassword,
            };
            let response = await UserActions.resetPassword(request);
            return response;
        }
        finally
        {
            this.instance?.setState({updating: false});
        }
    }

    private static checkPasswordConfirmed(form: any, passwordId: string, confirmId: string) {
        let passwordInput = $(form).find("#" + passwordId)[0] as HTMLInputElement;
        let confirmInput = $(form).find("#" + confirmId)[0] as HTMLInputElement;
        var result = passwordInput.value == confirmInput.value;
        if (!result) {
            confirmInput.setCustomValidity("Passwords do not match.");
        }
        else {
            confirmInput.setCustomValidity("");
        }
        return result;
    }

    private static checkPasswordValid(form: any, id: string): string | null {
        let passwordInput = $(form).find("#" + id)[0] as HTMLInputElement;
        passwordInput.setCustomValidity("");
        let pw = passwordInput.value;
        if (F.isNullOrWhitespace(pw)) {
            passwordInput.setCustomValidity("Password does not meet the minimum requirements.");
            return pw;
        }
        if (!F.isValidPassword(pw)) {
            passwordInput.setCustomValidity("Password does not meet the minimum requirements.");
            return null;
        }
        return pw;
    }
}

export default PasswordEntryForm;
