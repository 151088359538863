import { Auth0ContextInterface } from "@auth0/auth0-react";
import { ConfigurationActions } from "./ControllerActionHelpers/ConfigurationActions";
import * as vms from "./ViewModels";

export class F {
    public static isNullOrWhitespace(s?: string | null): boolean {
        if (s == null) {
            return true;
        }
        if (typeof s != "string") {
            return true;
        }
        return s.trim().length == 0;
    }

    public static isValidEmailAddress(s?: string): boolean {
        if (this.isNullOrWhitespace(s)) {
            return false;
        }
        let emailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;        
        return emailRE.test(s!);
    }

    public static isValidPassword(password?: string): boolean {
        if (this.isNullOrWhitespace(password)) {
            return false;
        }

        const lengthRequirement = password!.length >= 8;
        const hasLowerCase = /[a-z]/.test(password!);
        const hasUpperCase = /[A-Z]/.test(password!);
        const hasNumber = /\d/.test(password!);
        const hasSpecialChar = /[!@#$%^&*]/.test(password!);
        
        // Count the number of met criteria
        const criteriaMet = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar].filter(Boolean).length;
        
        // Password is valid if it meets at least 3 out of 4 criteria and the length requirement
        return lengthRequirement && criteriaMet >= 3;
    }

    public static clone(src: any): any {
        if (!src) {
            return src;
        }
        return JSON.parse(JSON.stringify(src));
    }

    public static async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public static getAge(dob?: Date): number | undefined {
        if (!dob) {
            return undefined;
        }
        if (typeof(dob) == "string") {
            dob = new Date(dob);
        }
        var today = new Date();
        var age = today.getFullYear() - dob!.getFullYear();
        var m = today.getMonth() - dob!.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dob!.getDate())) {
            age--;
        }
        return age;
    }

    public static isMobileDevice(): boolean {
        return window.innerWidth < 768;//768 is the bootstrap cutoff for medium
        //return window.innerWidth < window.innerHeight;
        // var appVersion = navigator.userAgent.toLowerCase();
        // for (var mobilePlatform of ["android", "iphone", "ipad"]) {
        //     if (appVersion.indexOf(mobilePlatform) != -1) {
        //         return true;
        //     }
        // }
        // return false;        
    }

    public static getOrganizationTypeName(ot?: vms.OrganizationType): string {
        switch (ot) {
            case vms.OrganizationType.Clinic: return "Clinic";
            case vms.OrganizationType.ED: return "ED";
            case vms.OrganizationType.PI: return "PI";
            case vms.OrganizationType.Research: return "Research";
            case vms.OrganizationType.Other: return "Other";
            default: return "Unknown";
        }
    }

    public static getDateString(dt?: Date, yyyymmdd?: boolean) : string {
        try {
            if (!dt) {
                return "";
            }
            if (typeof(dt) == "string") {
                //dt = new Date(JSON.parse(dt));
                dt = new Date(dt);
            }
            if (dt.getUTCFullYear() == 1) {
                return "";
            }
            if (yyyymmdd) {
                return dt.getUTCFullYear().toString().padStart(4, "0") + "-" + (dt.getUTCMonth() + 1).toString().padStart(2, "0") + "-" + dt.getUTCDate().toString().padStart(2, "0");
            }
            return (dt.getUTCMonth() + 1).toString().padStart(2, "0") + "/" + dt.getUTCDate().toString().padStart(2, "0") + "/" + dt.getUTCFullYear().toString().padStart(4, "0");
        }
        catch (x) {
            return dt? dt.toString() : "";
        }
    }

    public static getTimeString(dt?: Date) : string {
        try {
            if (!dt) {
                return "";
            }
            if (typeof(dt) == "string") {
                dt = new Date(dt);
            }
            let meridian = "AM";
            let hour = dt.getHours();
            if (hour >= 12) {
                meridian = "PM";
                if (hour > 12) {
                    hour -= 12;
                }
            }
            return hour + ":" + dt.getMinutes().toString().padStart(2, "0") + " " + meridian + " " + this.getTimeZoneAbbreviation(dt);
        }
        catch (x) {
            return dt ? dt.toTimeString() : "";
        }
    }

    public static getDateTimeString(dt?: Date) {
        return this.getDateString(dt) + " " + this.getTimeString(dt);
    }

    public static debugEvent(e: any) {
        console.log(e);
    }

    public static buildPatientNameOrAltIDs(patient?: vms.IPatient) {
        if (!patient) {
            return "[UNKNOWN]";
        }
        let result = this.concatComponents(patient.lastName, ", ", patient.firstName);
        if (!result) {
            result = this.concatComponents(patient.altId1, " - ", patient.altId2);
        }
        if (!F.isNullOrWhitespace(result)) {
            return result;
        }
        return "[UNKNOWN]";
    }

    private static concatComponents(a: string, concat: string, b: string): string | undefined {
        if (!F.isNullOrWhitespace(a)) {
            if (!F.isNullOrWhitespace(b)) {
                return a + concat + b;
            }
            return a;
        }        
        if (!F.isNullOrWhitespace(b)) {
            return b;
        }
        return undefined;
    }

    private static getTimeZoneAbbreviation(d: Date): string {
        try {
            var s = d.toString();
            var i = s.indexOf("(");
            s = s.substring(i + 1);
            i = s.indexOf(")");
            s = s.substring(0, i - 1);
            let parts = s.split(" ");
            let result = "";
            for (let p = 0; p < parts.length; p++) {
                result += parts[p][0];
            }
            return result;
        }
        catch {
            return "";
        }
    }

    public static buildScanDateTime(scan: vms.IScan) {
        var d = new Date(scan.scanDateTime);
        return d.toLocaleString() + " " + this.getTimeZoneAbbreviation(d);
    }

    public static isEightDigitDate(s: string) {
        if (this.isNullOrWhitespace(s)) {
            return false;
        }
        if (s.length != 8) {
            return false;
        }
        const numericChars = "0123456789";
        for (let c of s) {
            if (numericChars.indexOf(c) == -1) {
                return false;
            }
        }
        return true;
    }

    public static checkFormatEightDigitDate(s: string) {
        if (!this.isEightDigitDate(s)) {
            return s;
        }
        let year = new Number(s.substring(0, 4));
        let month = new Number(s.substring(4, 6));
        let day = new Number(s.substring(6, 8));
        let result = new Date(year.valueOf(), month.valueOf() - 1, day.valueOf(), 0, 0, 0, 0);
        return this.getDateString(result);
    }

    public static getSubjectLabel(): string {
        return window.domainConfig?.subjectLabel ?? "Subject";
    }

    public static getSubjectsLabel(): string {
        return window.domainConfig?.subjectsLabel ?? "Subjects";
    }

    public static showLogin(auth0: Auth0ContextInterface) {
        const host = window.origin.toLowerCase();
        let searchParams = new URLSearchParams(window.location.search);
        //alert(`Using audience: '${window.domainConfig?.authAudience}'`);
        let loginHint = searchParams.get("email") ?? undefined;
        let screenHint = (searchParams.get("email") ? "signup" : undefined);
        //alert("showLogin: " + (loginHint ?? "nologinhint") + ": " + screenHint);

        auth0.loginWithRedirect({
          audience: window.domainConfig?.authAudience,
          authorizationParams: JSON.stringify({
            host,
            icon:  window.domainConfig?.authLogo,
            primaryBackground: window.domainConfig?.cssVariables["--primary-background-color"],
            primaryButtonBackground: window.domainConfig?.cssVariables["--rounded-button-background-color"],
            titleBackground: window.domainConfig?.cssVariables["--header-background-color"],
            foreground: window.domainConfig?.cssVariables["--light-text-color"],
            title: window.domainConfig?.title,
            favicon: window.domainConfig?.favicon,
          }),
          login_hint: loginHint,
          screen_hint: screenHint,
        });
    }

    //This should only be used during onChange of a control where the
    //focus returning to the control is desired
    public static resetValidation(element: HTMLInputElement) {
        if (element && !element.validity.valid) {
            element.setCustomValidity("");
            element.reportValidity();
            element.blur();
            element.focus();
        }
    }

    public static isCannabisScan(scan?: vms.IScan) {
        let app = scan?.application != undefined ? scan.application : vms.Application.Undefined;
        console.log("Application Title:", window.domainConfig?.title, ", Scan.Application:", vms.Application[app]);
        if (scan) {
            //easy, if the new application property is set to cannabis
            if (app == vms.Application.Cannabis) {
                return true;
            }
            //if there is no value specified then it depends on the url
            if (app == vms.Application.Undefined) {
                return window.domainConfig?.title?.toLowerCase().indexOf("ocupro") != -1;
            }
        }
        return false;
    }

    public static isConcussionScan(scan?: vms.IScan) {
        let app = scan?.application ?? vms.Application.Undefined;
        console.log("Application Title:", window.domainConfig?.title, ", Scan.Application:", vms.Application[app]);
        if (scan) {
            //easy, if the new application property is set to concussion
            if (app == vms.Application.Concussion) {
                return true;
            }
            //if there is no value specified then it depends on the url
            if (app == vms.Application.Undefined) {
                return window.domainConfig?.title?.toLowerCase().indexOf("eyebox") != -1;
            }
        }
        return false;
    }

    public static determinePatientScanTypes(patient: vms.IPatient): vms.ScanTypes {
        let result = new vms.ScanTypes();
        for (let session of patient.sessions) {
            if (!session.scans) {
                continue;
            }
            for (let scan of session.scans) {
                if (F.isCannabisScan(scan)) {
                    result.cannabis = true;
                }
                else if (F.isConcussionScan(scan)) {
                    result.concussion = true;
                }                
            }
            if (result.isAll()) {
                break;
            }
        }
        return result;
    }
}