import React, { useEffect, useState } from 'react';
import './OrganizationUsers.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UserCard from '../UserCard/UserCard';
import { EyeBOXUser } from '../ViewModels';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import CreateUserForm from '../CreateUserForm/CreateUserForm';
import { UserActions } from '../ControllerActionHelpers/UserActions';
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';

interface OrganizationUsersState {
    org: vms.IOrganization;
}

interface OrganizationUsersProps {
    org: vms.IOrganization;
    userInfo: vms.EyeBOXUser;
    onUsersUpdated: () => void;
}

class OrganizationUsers extends React.Component<OrganizationUsersProps, OrganizationUsersState> {
    constructor(props: OrganizationUsersProps, state: OrganizationUsersState) {
        super(props);
        this.state = {
            org: props.org,
        };
    }

    render() {
        return <div className='OrganizationUsers'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <div>
                <div className='row'>
                    <div className='col-4'>
                        <ul className='primary-item-list'>
                            <li>Users</li>
                        </ul>
                    </div>
                    <div className='col-8 right'>
                        {this.canAddUsers()
                            ? <a className='rounded-button-blue' onClick={e => this.addUser()}>ADD USER</a>
                            : null
                        }
                    </div>
                </div>
                {this.buildUserCards()}
            </div>)
    }

    private canAddUsers() {
        return this.props.userInfo.HasRole(vms.EyeBOXUserRole.Admin, vms.EyeBOXUserRole.Super);
    }

    private getOrderedUsers(): Array<vms.IEyeBOXUser> {
        var allUsers: Array<vms.IEyeBOXUser> = [];

        this.state.org?.users?.map((user, index, users) => {
            allUsers.push(user);
        });

        //now sort the scans
        let result = allUsers.sort((a, b) => {
            return (a.name?.toLowerCase()??"").localeCompare(b.name?.toLowerCase()??"")
        });

        return result;
    }

    private buildUserCards(): Array<any> {
        var result: Array<any> = [];        
        this.getOrderedUsers().map((user, index, users) => {
            result.push(<UserCard
                     key={users.length + "-" + index}
                     user={new EyeBOXUser(user)}
                     currentUser={this.props.userInfo}
                     onUpdated={() => {
                        this.props.onUsersUpdated();
                        this.refreshOrganization();
                     }}
                   />)
        });
        if (result.length == 0) {
            result.push(<span key='nousers'>No users found</span>);
        }
        return result;
    }

    private async addUser() {
        if (!this.canAddUsers()) {
            ApplicationToast.Show("You are not authorized to add users.");
            return;
        }
        var form: any;
        var add = await ApplicationModal.showForm(<CreateUserForm
            ref={e => { form = e ?? form; }}
            isSuper={this.props.userInfo.HasRole(vms.EyeBOXUserRole.Super)}
            initialValues={{organizationId: this.state.org.id, enabled: true, stoppedViaSms: false, confirmedProfile: false }}
            isCreating={true}
            isSelf={false}
        />, "Add User", (form) => {
            //todo: include prevention of Super or Admin disabling themself
            return CreateUserForm.OnValidate(form);
        });
        if (add) {
            await UserActions.add(form.state)
                .then(async addedUser => {
                    if (addedUser) {
                        this.props.onUsersUpdated();
                        await this.refreshOrganization();
                    }
                })
                .catch(ControllerActions.reportError);
        }
    }

    private async refreshOrganization() {
        var org = await OrganizationActions.get();
        if (org) {
            this.setState({org});
        }
    }
}

export default OrganizationUsers;
