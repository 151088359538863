import React, { CSSProperties, useEffect, useState } from 'react';
import './LoadingWidget.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface LoadingWidgetState {
}

interface LoadingWidgetProps {
    message?: string;
    fontSize?: number;
    compact?: boolean;
}

class LoadingWidget extends React.Component<LoadingWidgetProps, LoadingWidgetState> {
    constructor(props: LoadingWidgetProps, state: LoadingWidgetState) {
        super(props);
        this.state = state;
    }

    render() {
        return (
            <div className='LoadingWidget row'>
                <div className='col-12 middle'>
                    <div className='full-width spinner'>
                        {
                            this.props.message
                            ? <div
                                className={(this.props.compact ? "small " : "") + "need-top-margin"}
                                style={this.getFontStyleOverride()}>
                                {this.props.message}
                            </div>
                            : null
                        }
                        <img className={this.props.compact ? "small need-top-margin" : "need-top-margin need-left-margin"} alt="Please wait..." src='/Spinner.gif'></img>
                    </div>
                </div>
            </div>
        );
    }

    private getFontStyleOverride() {
        if (!this.props.fontSize) {
            return undefined;
        }
        let result: CSSProperties = {};
        result.fontSize = this.props.fontSize + "px";
        return result;
    }
}

export default LoadingWidget;
