import './ConcussionScanCard.css';
import { F } from '../Functions';
import BOXScore from '../BOXScore/BOXScore';
import {ScanCardViewBase, ScanCardViewBaseProps, ScanCardViewBaseState} from '../ScanCardViewBase/ScanCardViewBase';

interface ConcussionScanCardState extends ScanCardViewBaseState {
}

interface ConcussionScanCardProps extends ScanCardViewBaseProps {
    
}

class ConcussionScanCard extends ScanCardViewBase<ConcussionScanCardProps, ConcussionScanCardState> {

    constructor(props: ConcussionScanCardProps, state: ConcussionScanCardState) {
        super(props);
        this.state = state;
    }

    render() {
        return <div className='ConcussionScanCard'>
            <div className='row no-gutters centered'>
                <div className='col-12 data-value'>{F.getDateTimeString(this.props.scan.scanDateTime)}</div>
            </div>
            <div className='row no-gutters centered'>
                <div className='col-12 data-value days-ago'>({this.getTodayOrNDaysAgo()})</div>
            </div>
            <div className='row no-gutters box-score-container'>
                <div className='col-12 data-value'><BOXScore scan={this.props.scan} /></div>
            </div>
            {this.buildScanActionButtons({
                includePdf: true,
                includeDonwloadVideo: false,
            })}
        </div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

}

export default ConcussionScanCard;
