import './CannabisScanCard.css';
import { F } from '../Functions';
import BOXScore from '../BOXScore/BOXScore';
import {ScanCardViewBase, ScanCardViewBaseProps, ScanCardViewBaseState} from '../ScanCardViewBase/ScanCardViewBase';
import * as vms from '../ViewModels';
import CannabisScore from '../CannabisScore/CannabisScore';

interface CannabisScanCardState extends ScanCardViewBaseState {
}

interface CannabisScanCardProps extends ScanCardViewBaseProps {
    patient?: vms.IPatient;    
}

class CannabisScanCard extends ScanCardViewBase<CannabisScanCardProps, CannabisScanCardState> {

    constructor(props: CannabisScanCardProps, state: CannabisScanCardState) {
        super(props);
        this.state = state;
    }

    render() {
        let normalityClass = "normality ";
        switch (this.props.scan.normality) {
            case vms.Normality.Indeterminant:
                normalityClass += "indeterminant";
            break;
            case vms.Normality.Abnormal:
                normalityClass += "abnormal";
            break;
            case vms.Normality.Normal:
                normalityClass += "normal";
            break;
        }
        return <div className='CannabisScanCard'>
            {
                this.props.scan.completeUpload
                ? null
                : (
                    <div className='row no-gutters centered'>
                        <div className='col-12 data-value upload-incomplete'>
                            Incomplete Upload!
                        </div>
                    </div>
                )
            }
            <div className='row no-gutters centered'>
                <div className='col-12 data-value'>
                    Report ID: <span className='swiper-no-swiping'>{this.props.scan.scanID}</span>
                </div>
            </div>
            <div className='row no-gutters centered'>
                <div className='col-12 data-value'>
                    Device ID: <span className='swiper-no-swiping'>{this.props.scan.deviceId}</span>
                </div>
            </div>
            <div className='row no-gutters centered'>
                <div className='col-12 data-value'>{F.getDateTimeString(this.props.scan.scanDateTime)}</div>
            </div>
            <div className='row no-gutters centered '>
                <div className='col-12 data-value days-ago'>({this.getTodayOrNDaysAgo()})</div>
            </div>
            <div className='row no-gutters  scan-result'>
                <div className='col-12 data-value'>
                    Age: {this.getAgeDisplay()} 
                </div>
            </div>
            <div className='row no-gutters '>
                <div className='col-12 data-value'>
                    Gender: {this.props.patient?.gender ?? "UNKNOWN"}
                </div>
            </div>
            <div className='row no-gutters '>
                <div className='col-12 data-value'>
                    Result: <span className={normalityClass}>{ vms.Normality[this.props.scan.normality] }</span>
                </div>
            </div>
            <div className='row no-gutters cannabis-score-container'>
                <div className='col-12 data-value'><CannabisScore scan={this.props.scan} /></div>
            </div>
            {this.buildScanActionButtons({
                includeDonwloadVideo: true,
                playVideoText: "View video",
                includePdf: false
            })}
        </div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private getAgeDisplay() {
        if (!this.props.patient || !this.props.patient.age) {
            return "UNKNOWN";
        }
        return this.props.patient.age + " years";
    }
}

export default CannabisScanCard;
