import React, { cloneElement } from 'react';
import './App.css';
import { RedirectLoginOptions, useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Landing from "../Landing/Landing";
import Admin from "../Admin/Admin"
import Organization, { OrganizationViewMode } from '../Organization/Organization';
import SuperAdmin from "../Super/Super";
import * as vms from '../ViewModels'
import { PageActions } from "../PageActions"
import { ControllerActions } from "../ControllerActionHelpers/ControllerActions"
import { UserActions } from "../ControllerActionHelpers/UserActions"
import ApplicationTitle from '../ApplicationTitle/ApplicationTitle';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { PageScroller } from "../PageScroller";
import { F } from "../Functions";
//import SubjectScan from '../SubjectScan/SubjectScan';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import { ConfigurationActions } from '../ControllerActionHelpers/ConfigurationActions';
import UserInactivityTracker from '../UserInactivityTracker';
import UnsubscribeEmailPage from '../UnsubscribeEmailPage/UnsubscribeEmailPage';
import CreateUserForm from '../CreateUserForm/CreateUserForm';
import PasswordEntryForm from '../PasswordEntryForm/PasswordEntryForm';

interface AppState {
  userToken?: string;
  userInfo?: vms.IEyeBOXUser;
  isLoaded: boolean;
}

interface LandingProps extends WithAuth0Props {

}

class App extends React.Component<WithAuth0Props, AppState> {
  private _userTokenRequest: any = null;
  private requestedConfig = false;
  private inactivityTracker: UserInactivityTracker | null = null;

  constructor(props: WithAuth0Props, state: AppState) {
    super(props);
    this.state = { isLoaded: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  private pageActionUnlisten?: { (): void } = undefined;

  componentDidMount() {
    this.pageActionUnlisten = PageActions.Listen(() => {
      this.setState({})
    });
    if (!this.requestedConfig) {
      this.requestedConfig = true;
      ConfigurationActions.addUpdateListener(() => {
        this.forceUpdate();
        ApplicationTitle.Set({});
      });
      ConfigurationActions.getConfiguration();
    }

    const handleUserInactivity = () => {
      if (this.props.auth0.isLoading) {
        return;
      }
      if (!this.props.auth0.isAuthenticated) {
        return;
      }
      //alert('User is inactive for too long!');
      // Add any logic here, such as logging out the user or showing a warning
      this.props.auth0.logout({ returnTo: window.location.origin });
    };

    // Initialize the UserInactivityTracker with a 15-minute timeout
    //this.inactivityTracker = new UserInactivityTracker(15, handleUserInactivity);    
    this.inactivityTracker = new UserInactivityTracker(15 * 60, handleUserInactivity);    
  }

  componentDidUpdate(prevProps: Readonly<WithAuth0Props>, prevState: Readonly<AppState>, snapshot?: any): void {
    if (this.state.userInfo && this.state.userInfo.enabled && !this.state.userInfo.confirmedProfile) {
      console.log("Calling editSelf for profile confirmation");
      this.editSelf(true);
    }
  }

  componentWillUnmount() {
    if (this._userTokenRequest && typeof this._userTokenRequest.cancel == "function") {
      this._userTokenRequest.cancel();
    }
    if (this.pageActionUnlisten != undefined) {
      this.pageActionUnlisten();
      this.pageActionUnlisten = undefined;
    }

    if (this.inactivityTracker) {
      let tempTracker = this.inactivityTracker;
      this.inactivityTracker = null;
      tempTracker.cleanup();
    }
  }

  render() {
    if (this.props.auth0.isLoading) {
      return null;
    }
    if (this.checkIsRegistering()) {
      return null;
    }

    if (this.checkInitiatePasswordReset()) {
      return null;
    }

    return (
      <div>
        <Router>
          <div className='App'>
            <div className='header row align-items-center '>
              <div className='col-12 confined-width'>
                <div className='row align-items-center no-gutters'>
                  <div className='col-10 col-md-6 col-lg-4 left'>
                    <Link to="/">
                      <div className={`logo-container ${F.isMobileDevice() ? "mobile" : ""}`}>
                            <img className='logo' src={window.domainConfig?.logo} alt="Company Logo" />
                            <span className={'text cloud-access' + (F.isMobileDevice() ? " mobile" : "")} style={{marginLeft:5}}>Cloud</span>
                      </div>
                    </Link>
                  </div>
                  {/* The page component must display its title for sizes smaller than lg */}
                  <div className='col-5 d-none d-lg-block middle' >
                    <ApplicationTitle />
                  </div>
                  <div className='d-none d-md-block col-md-6 col-lg-3 right'>
                    {this.BuildLogInOrOut(false)}
                  </div>
                  <div className='col-2 d-block d-md-none'>
                    {this.BuildLogInOrOut(true)}
                  </div>
                </div>
                <div className='row align-items-center d-lg-none'>
                  <div className='col-12'>
                    <SecondaryTitle />
                  </div>
                </div>
              </div>
            </div>
            <div className='non-header' ref={e => { if (e != null) { PageScroller.Monitor(e) } }}>
              <div className='content confined-width'>
                {
                  this.props.auth0.isLoading ? <LoadingWidget message='Loading...'/> :
                    this.props.auth0.error ? <div>{this.props.auth0.error.message}</div> :
                      this.props.auth0.isAuthenticated && !this.state.isLoaded ? this.LoadUser() :
                        this.state?.userInfo == null || this.state?.userToken == null ?
                          (
                            <Landing auth0={this.props.auth0} />
                          ) :
                          (
                            <Routes>
                              <Route path='' element={<Landing auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/admin' element={<Admin auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization' element={<Organization viewMode={OrganizationViewMode.Subjects} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization/subjects' element={<Organization viewMode={OrganizationViewMode.Subjects} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization/subjectdetail' element={<Organization viewMode={OrganizationViewMode.SubjectDetail} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization/users' element={<Organization viewMode={OrganizationViewMode.Users} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization/reports' element={<Organization viewMode={OrganizationViewMode.Reports} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization/devices' element={<Organization viewMode={OrganizationViewMode.Devices} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/organization/resources' element={<Organization viewMode={OrganizationViewMode.Resources} auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/super' element={<SuperAdmin auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              <Route path='/unsubscribe' element={<UnsubscribeEmailPage auth0={this.props.auth0} userInfo={this.state.userInfo} userToken={this.state.userToken} />} />
                              {/* <Route path='/subjectScan/*' element={<SubjectScan ></SubjectScan>} /> */}
                            </Routes>
                          )
                }
              </div>
              <div className='row footer no-gutters full-width '>
                <div className='col-12 confined-width'>
                  <div className='row no-gutters'>
                <div className='col-0 col-md-1'></div>
                <div className='d-md-none' style={{width:"10px"}}></div>
                <div className='col-5 cell'>
                  <div className='row no-gutters'>
                    <a href={window.domainConfig?.oculogicaURL}>
                      <img className='d-none d-md-block' src={window.domainConfig?.oculogicaLogo} height={40}></img>
                      <img className='d-md-none' src={window.domainConfig?.oculogicaLogo} height={24}></img>
                    </a>
                  </div>
                  <div className='row no-gutters'>&nbsp;</div>
                  <div className='row no-gutters'>
                    {
                      F.isNullOrWhitespace(window.domainConfig?.facebookURL)
                      ? null
                      : (<div className='icon-circle'>
                        <a href={window.domainConfig?.facebookURL} target='_blank'>
                          <i className='fab fa-facebook'></i>
                        </a>
                      </div>)
                    }
                    {
                      F.isNullOrWhitespace(window.domainConfig?.twitterURL)
                      ? null
                      : (<div className='icon-circle'>
                        <a href={window.domainConfig?.twitterURL} target='_blank'>
                          <i className='fab fa-twitter'></i>
                        </a>
                      </div>)
                    }
                    {
                      F.isNullOrWhitespace(window.domainConfig?.linkedInURL)
                      ? null
                      : (<div className='icon-circle'>
                        <a href={window.domainConfig?.linkedInURL} target='_blank'>
                          <i className='fab fa-linkedin'></i>
                        </a>
                      </div>)
                    }
                    {
                      F.isNullOrWhitespace(window.domainConfig?.instagramURL)
                      ? null
                      : (<div className='icon-circle'>
                        <a href={window.domainConfig?.instagramURL} target='_blank'>
                          <i className='fab fa-instagram'></i>
                        </a>
                      </div>)
                    }
                  </div>
                </div>
                <div className='d-none col-2 d-md-block'></div>
                <div className='col-6 col-md-4 cell contact-us-div'>
                  <p className='contact-us-p'>
                    Get in touch with us<br />
                    {window.domainConfig?.contactPhone ?? '(484) 393-2694'}<br />
                    <span className='contact-us-span'>
                      <a className='contact-us-a' href={`mailto:${window.domainConfig?.infoEmail || 'info@oculogica.com'}`} target="_blank" rel="noopener">
                        {window.domainConfig?.infoEmail || 'info@oculogica.com'}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              </div>
              </div>
            </div>
            {/* <div className='copyright-footer'>
							<p className='copyright-footer-content'>© {new Date().getFullYear()} Oculogica ®</p>
            </div> */}
          </div>
        </Router>
        <ApplicationModal />
        <ApplicationToast />
      </div>
    );
  }

  private LoadUser() {
    this.FetchUserInfo();
    return <LoadingWidget message='Loading User...'/>
  }

  private FetchUserInfo() {
    if (null != this.state.userInfo || null != this._userTokenRequest) {
      //we either already have the info or we have alreaady requested it
      return;
    }
    ControllerActions.setUserToken(undefined);
    if (this.props.auth0.isAuthenticated) {
      this._userTokenRequest = this.props.auth0.getAccessTokenSilently().then(token => {
        ControllerActions.setUserToken(token);
        this._userTokenRequest = null;
        UserActions.getMe().then(info  => {
          this.setState({
            userToken: token,
            userInfo: info,
            isLoaded: true
          });
        });
      });
    }
  }

  private BuildLogInOrOut(iconOnly: boolean) {
    if (this.props.auth0.isAuthenticated) {
      return (
        <div className='dropdown'>
          <a className={"data-toggle" + (iconOnly ? "" : " rounded-button")}
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true">
              {iconOnly ? (
                <img height="40px" src={this.props.auth0.user?.picture} alt="..." title={this.props.auth0.user?.name}></img>
              ) : (
                <span >
                  {this.state.userInfo ? this.state.userInfo.name : this.props.auth0.user?.name}
                </span>
              )}
          </a>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div className='dropdown-list'>
              <div className='d-block d-md-none'>
                {this.state.userInfo ? this.state.userInfo.name : this.props.auth0.user?.name}
                <hr />
              </div>
              <div>
                <a className='btn-secondary' onClick={() => this.editSelf()}>
                  My Profile
                </a>
              </div>
              <div>
                <a className='btn-secondary' onClick={() => this.editPassword()}>
                  Update Password
                </a>
              </div>
              <div>
                <a className='btn-secondary' onClick={() => this.props.auth0.logout({ returnTo: window.location.origin })}>
                  Sign Out
                </a>
              </div>
              {this.buildEndImpersonation()}
              {this.BuildPageActions()}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='d-none d-md-block'>
        <a className="rounded-button" onClick={() => {
          F.showLogin(this.props.auth0);
        }}>SIGN IN</a>
      </div>
    )
  }

  private buildChangePasswordLink() {
    const auth0Domain = window.domainConfig?.authDomain;
    const clientID = window.domainConfig?.authClientId;
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    const connectionName = "Username-Password-Authentication";
    const resetPasswordUrl = `https://${auth0Domain}/dbconnections/change_password?client_id=${clientID}&email=${this.state.userInfo?.email}&connection=${connectionName}&redirect_uri=${baseUrl}&extraParam=something`;
    return resetPasswordUrl;    
  }

  private buildEndImpersonation() {
    if (F.isNullOrWhitespace(this.state.userInfo?.currentOrganizationId)) {
      return null;
    }
    return (
      <div>
        <a className='btn-secondary' onClick={() => this.endOrganizationView()}>
          Close Organization
        </a>
      </div>
    )
  }

  private async endOrganizationView() {
    UserActions.unsetCurrentOrganization()
      .then(updated => { if (updated) window.location.assign("/") })
      .catch(ControllerActions.reportError);
  }

  private TestRoles(user: vms.IEyeBOXUser, ...roles: Array<string>) {
    let eyeBOXUser = new vms.EyeBOXUser(user);
    try {
      console.log("User Roles: " + user.roles + ", TestRole(s): " + roles);
      if (eyeBOXUser.HasRole(...roles)) {
        if (eyeBOXUser.roles?.length == 1) {
          console.log("User has roles " + eyeBOXUser.roles);
        }
        else {
          console.log("User has role " + eyeBOXUser.roles);
        }
      }
      else {
        console.log("User does not have role " + roles);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  private DebugTestRoles(eyeBOXUser: vms.EyeBOXUser) {
    var initialRoles = JSON.parse(JSON.stringify(eyeBOXUser.roles));
    eyeBOXUser.roles = [vms.EyeBOXUserRole.Normal, vms.EyeBOXUserRole.Super];
    this.TestRoles(eyeBOXUser, vms.EyeBOXUserRole.Admin);
    this.TestRoles(eyeBOXUser, vms.EyeBOXUserRole.Normal);
    this.TestRoles(eyeBOXUser, vms.EyeBOXUserRole.Super);
    this.TestRoles(eyeBOXUser, vms.EyeBOXUserRole.Normal, vms.EyeBOXUserRole.Super);
    eyeBOXUser.roles = initialRoles;
  }

  private BuildPageActions() {    
    let result: Array<any> = [];
    let pageActions = PageActions.Enumerate();
    for (var pageAction in pageActions) {
      result.push((
        <div key={pageAction}>
          <a
            onClick={pageActions[pageAction]}>
            {pageAction}
          </a>
        </div>));
    }
    //insert a horizontal rule only if there are page actions to display
    if (result.length != 0) {
      result.splice(0, 0, <hr key="page-action-separator" />);
    }
    return result;
  }

  private async editSelf(confirmingProfile?: boolean) {
    var form: any;
    var user = new vms.EyeBOXUser(this.state.userInfo);
    var edit = await ApplicationModal.showForm(<CreateUserForm
        ref={e => { form = e ?? form; }}
        isSuper={user.HasRole(vms.EyeBOXUserRole.Super)}
        initialValues={F.clone(user)}
        isCreating={false}
        isSelf={true}
        confirmingProfile={confirmingProfile}        
    />, "My Profile", (form) => {
        return CreateUserForm.OnValidate(form);
    }, (confirmingProfile ? "Confirm" : "OK"));
    if (edit) {
        await UserActions.updateSelf(form.state)
            .then(updatedUser => {
                if (updatedUser) {
                    this.setState({ userInfo: updatedUser });
                    ApplicationToast.Show("Your profile was updated");
                }
            })
            .catch(ControllerActions.reportError);
    }
  }

  private checkIsRegistering() {
    if (window.location.pathname.toLowerCase().endsWith("register")
      || window.location.hash.toLowerCase() == "#register") {
      //handle the case where someone is currently logged in but a new user wants to register
      if (this.props.auth0.isAuthenticated) {
        setTimeout(() => {
          //console.log("app logout");
          this.props.auth0.logout({ returnTo: window.location.origin + this.includeEmailQueryString() + "#register" });
        }, 0);
      }
      else {
        setTimeout(() => {
          F.showLogin(this.props.auth0);
        }, 0);
      }
      return true;
    }
    return false;
  }

  private includeEmailQueryString(): string {
    if (window.location.search && window.location.search.length > 0) {
      return window.location.search;
    }
    return "";
  }

  private async editPassword() {
    if (F.isNullOrWhitespace(this.state.userInfo?.email)) {
      ApplicationModal.showError("The user information is unavailable.");
      return;
    }
    var form: any;
    var user = new vms.EyeBOXUser(this.state.userInfo);
    var success = await ApplicationModal.showForm(<PasswordEntryForm
        ref={e => { form = e ?? form; }}
        email={this.state.userInfo?.email!}
        requireCurrentPassword={true}
    />, "Update Password", undefined, undefined, undefined, undefined, async (form) => {
      return await PasswordEntryForm.OnValidateAsync(form);
    });
    if (success) {
      ApplicationToast.Show("Your password was updated.");
    }
  }

  private checkInitiatePasswordReset() {
    if (window.location.pathname.toLowerCase().endsWith("resetpassword")
      || window.location.hash.toLowerCase() == "#resetpassword") {
      //handle the case where someone is currently logged in but a new user wants to register
      if (this.props.auth0.isAuthenticated) {
        setTimeout(() => {
          //console.log("app logout");
          this.props.auth0.logout({ returnTo: window.location.href });
        }, 0);
        return true;
      }
      else {
        setTimeout(async () => {
          await this.showResetPassword();
        }, 0);        
      }
    }
    return false;
  }

  private async showResetPassword() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const email = params.get("email") || "";
    const token = params.get("token") || "";

    if (F.isNullOrWhitespace(email) || F.isNullOrWhitespace(token)) {
      ApplicationModal.showError("The specified URL is missing a required component.");
      setTimeout(() => {
        window.location.href = "/";
      }, 0);
      return;
    }
    var form: any;
    var success = await ApplicationModal.showForm(<PasswordEntryForm
        ref={e => { form = e ?? form; }}
        email={email}
        token={token}
        requireCurrentPassword={false}
    />, "Reset Password", undefined, undefined, undefined, undefined, async (form) => {
      return await PasswordEntryForm.OnValidateAsync(form);
    });
    if (success) {
      //if there is an invalidTokenMessage then we need to remind the user to check their email
      if (F.isNullOrWhitespace(form?.state?.invalidTokenMessage)) {
        await ApplicationModal.showMessage("Your password was updated");
      }
      else {
        const checkEmailMessage = "Check the inbox for " + email + " for password reset instructions.";
        await ApplicationModal.showMessage(checkEmailMessage, undefined, "Request submitted");
      }
    }
    window.location.href = "/";
  }
}

export default withAuth0(App);
