import React, { useEffect, useState } from 'react';
import './OrganizationResources.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import { PageActions } from '../PageActions';

interface OrganizationResourcesState {
    resourceRoot?: vms.ICloudResourceItem
}

interface OrganizationResourcesProps {

}

class OrganizationResources extends React.Component<OrganizationResourcesProps, OrganizationResourcesState> {
    constructor(props: OrganizationResourcesProps, state: OrganizationResourcesState) {
        super(props);
        this.state = state;
    }

    render() {
        return <div className='OrganizationResources'>{this.BuildContent()}</div>
    }

    componentDidMount() {
        // PageActions.BeginUpdate();
        // try {
        //     PageActions.Add("Refresh Resource Cache", this.resetResourceCache.bind(this));
        // }
        // finally {
        //     PageActions.EndUpdate();
        // }

        if (!this.state.resourceRoot) {
            setTimeout(this.fetchResources.bind(this), 0);
        }
    }

    componentWillUnmount() {
    }

    private async resetResourceCache() {
        this.setState({resourceRoot: undefined})
        await OrganizationActions.resetResourceCache();
        await this.fetchResources();
    }

    private async fetchResources() {
        var resources = await OrganizationActions.getAvailableResources();
        this.setState({
            resourceRoot: resources
        });
    }

    private BuildContent() {
        if (!this.state.resourceRoot) {
            return <LoadingWidget message='Loading Resources...' />
        }
        return (
            <div className='detail-body table-rounded-container'>
                {this.buildResourceItems(this.state.resourceRoot.items)}
            </div>
        );
    }

    private buildResourceItems(items: Array<vms.ICloudResourceItem>): Array<any> {
        var result: Array<any> = [];
        this.state.resourceRoot?.items?.map((rootItem, rootIndex, rootItems) => {
            let rootKey = rootItems.length + "-" + rootIndex;
            result.push(this.buildItemRow(rootItem, rootKey, 0));
            if (rootIndex < rootItems.length - 1 || rootItem.items?.length > 0) {
                result.push(<hr key={"hr-" + rootIndex} />);
            }
            rootItem.items?.map((childItem, childIndex, childItems) => {
                result.push(this.buildItemRow(childItem, rootKey + "-" + childItems.length + "-" +  childIndex, 1));
                if (rootIndex < rootItems.length - 1 || childIndex < childItems.length - 1) {
                    result.push(<hr key={"hr-" + rootIndex + "-" + childIndex} />);
                }
            });
        });        
        return result;
    }

    private buildItemRow(item: vms.ICloudResourceItem, key: string, level: number) {
        let extension = this.getFileExtension(item);
        if (item.type == vms.CloudResourceItemType.Folder) {
            return (<div key={key} className='item-row' style={{marginLeft: (level * 30) + "px"}}>                
                <img src={this.getItemIcon(extension)} alt={extension} />
                <span key={key}>{item.name}</span>
            </div>) 
        }
        if (item.type == vms.CloudResourceItemType.Bookmark) {
            return (<div key={key} className='item-row' style={{marginLeft: (level * 30) + "px"}}>
                <img src={"/res-bookmark.png"} alt={extension} />
                <span>
                <a href={item.path} target="_blank">{item.name}</a>
                </span>
            </div>) 
        }
        return (<div key={key} className='item-row' style={{marginLeft: (level * 30) + "px"}}>
            <img src={this.getItemIcon(extension)} alt={extension} />
            <span>
            <a href={OrganizationActions.buildViewResource(item.path)} target="_blank">{item.name}</a>
            </span>             
        </div>) 
    }

    private getItemIcon(extension: string): string {
        switch (extension.toLowerCase()) {
            case ".fld": return "/res-folder.png";
            case ".pdf": return "/res-pdf.png";
            case ".doc":
            case ".docx": return "/res-word.png";
            default: return "/res-unknown.png";
        }
    }

    private getFileExtension(item: vms.ICloudResourceItem): string {
        if (item.items?.length > 0) {
            return ".fld";
        }
        var index = item.path.lastIndexOf(".");
        if (index == -1) {
            return ".unk";
        }
        return item.path.substring(index);
    }
}

export default OrganizationResources;
