import React, { useEffect, useState } from 'react';
import './CreateUserForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase from '../DialogFormBase/DialogFormBase';
import { F } from "../Functions";

interface CreateUserFormState extends vms.IEyeBOXUser {
}

interface CreateUserFormProps {
    isSuper: boolean;
    initialValues: vms.IEyeBOXUser;
    isCreating: boolean;
    isSelf: boolean;
    confirmingProfile?: boolean;
}

class CreateUserForm extends DialogFormBase<CreateUserFormProps, CreateUserFormState> {
    constructor(props: CreateUserFormProps, state: CreateUserFormState) {
        super(props);
        this.state = F.clone(this.props.initialValues);
    }

    private fetching: boolean = false;

    render() {
        return <div className='CreateUserForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {
                    this.props.confirmingProfile == true
                    ? (
                    <div>
                        Please confirm your profile information and include your mobile phone number if you'd like to receive SMS notifications.
                        <hr className='extended-hr'/>
                    </div>
                    )
                    : null
                }
                {this.buildStandardInput("user-email", "Email", this.state.email, e => this.setState({ email: e.target.value }), true, undefined, undefined, this.checkEmailIsReadOnly())}
                {this.buildStandardInput("user-name", "Name", this.state.name, e => this.setState({ name: e.target.value }), true)}
                
                {    this.props.isSelf && !this.props.isSuper ? null : (
                    <div>
                        {this.buildEnabledCheckbox()}
                        
                <label>Roles</label>
                <div>
                    {this.buildRoleCheckbox(vms.EyeBOXUserRole.Normal)}
                    {this.buildRoleCheckbox(vms.EyeBOXUserRole.Contributor)}
                    {this.buildRoleCheckbox(vms.EyeBOXUserRole.Admin)}
                    {this.props.isSelf ? null : (
                        <div>
                    {/* Only show the Super role to super users (those that can impersonate) */}                    
                    {!this.props.isSuper ? null : this.buildRoleCheckbox(vms.EyeBOXUserRole.Super)}
                        </div>
                    )}
                </div>
                <br/>
                </div>
                )
                }

                <label>Receive New Report Notifications via</label>
                <div className='checkbox-container'>
                    {this.buildNotificationTypeCheckbox(vms.NotificationType.Email)}
                    {this.buildNotificationTypeCheckbox(vms.NotificationType.Sms)}
                </div>

                {
                    this.buildStandardPhoneInput("user-phone", "", this.state.phone,
                        newValue => {
                            this.setState({ phone: newValue });
                        }, true,
                        () => {
                            var ebu = new vms.EyeBOXUser(this.state);
                            var needsPhone = ebu.HasNotificationType(vms.NotificationType.Sms);
                            return needsPhone;
                        }, false)
                }
                    {
                        this.checkSmsNotificationActivated()
                        ? (
                            <div className='sms-stop-warning'>
                                This user chose to STOP notifications, they will need to reply START to {window.domainConfig?.smsReplyPhone} to restore notifications.
                            </div>
                        )
                        : null
                    }

                {
                    this.props.isCreating
                    ? <div>The user will receive an invitation email when you click OK.</div>
                    : null
                }
                
            </form>
        );
    }

    private checkSmsNotificationActivated(): boolean {
        var initialUser = new vms.EyeBOXUser(this.props.initialValues);
        var currentUser = new vms.EyeBOXUser(this.state);
        if (initialUser.stoppedViaSms &&
            !initialUser.HasNotificationType(vms.NotificationType.Sms) &&
            currentUser.HasNotificationType(vms.NotificationType.Sms)) {
            return true;            
        }
        return false;
    }

    private checkEmailIsReadOnly(): boolean {
        return !this.props.isCreating;
    }

    private buildRoleCheckbox(role: string) {
        let isChecked = this.state.roles && this.state.roles.indexOf(role) != -1;
        return (
            <div className='row'>
                <div className='col-12'>
                <span key={role}
                      className='checkbox'
                      onClick={() => this.toggleRole(role)}
                      onKeyDown={event => {
                        if (event.key == " " && event.code == "Space") {
                            this.toggleRole(role);
                        }
                      }}
                      >
                    <i tabIndex={0} className={'fa-regular ' + (isChecked ? "fa-square-check" : "fa-square")}></i>
                    {vms.EyeBOXUserRole.getDescription(role)}
                </span>
                </div>
            </div>
        );
    }

    private buildEnabledCheckbox() {
        let isChecked = this.state.enabled;
        return (
            <div className='row'>
                <div className='col-12'>
                <span key="user-enabled"
                      className='checkbox'
                      onClick={() => this.setState({enabled: !this.state.enabled})}
                      onKeyDown={event => {
                        if (event.key == " " && event.code == "Space") {
                            this.setState({enabled: !this.state.enabled});
                        }
                     }}
                  >
                    <i tabIndex={0} className={'fa-regular ' + (isChecked ? "fa-square-check" : "fa-square")}></i>
                    Enable User
                </span>
                </div>
                <br/>
                <br/>
            </div>
        );
    }

    private buildNotificationTypeCheckbox(notificationType: string) {
        let isChecked = this.state.notificationTypes && this.state.notificationTypes.indexOf(notificationType) != -1;
        return (
            <div className='row'>
                <div className='col-12'>
                <span key={notificationType}
                      className='checkbox'
                      onClick={() => this.toggleNotificationType(notificationType)}
                      onKeyDown={event => {
                            if (event.key == " " && event.code == "Space") {
                                this.toggleNotificationType(notificationType);
                            }
                         }}
                      >
                    <i tabIndex={0} className={'fa-regular ' + (isChecked ? "fa-square-check" : "fa-square")}></i>
                    {notificationType == "Sms" ? "SMS Text Message" : notificationType}
                </span>
                </div>
            </div>
        );
    }

    private toggleRole(role: string) {
        let tempRoles = this.state.roles ?? [];
        let roleIndex = tempRoles.indexOf(role)
        if (roleIndex == -1) {
            tempRoles.push(role);
            this.setState({ roles: tempRoles });
        }
        else {
            tempRoles.splice(roleIndex, 1);
            this.setState({ roles: tempRoles });
        }
    }

    private toggleNotificationType(notificationType: string) {
        let tempTypes = this.state.notificationTypes ?? [];
        let typeIndex = tempTypes.indexOf(notificationType)
        if (typeIndex == -1) {
            tempTypes.push(notificationType);
            this.setState({ notificationTypes: tempTypes });
        }
        else {
            tempTypes.splice(typeIndex, 1);
            this.setState({ notificationTypes: tempTypes });
        }
    }

    public static OnValidate(form: any): boolean {        
        let emailValid = this.checkEmailValid(form);
        let phoneValid = this.checkPhoneValid(form);
        return emailValid && phoneValid;
    }

    private static checkEmailValid(form: any) {
        let emailInput = $(form).find("#user-email")[0] as HTMLInputElement;
        emailInput.setCustomValidity("");        
        let email = emailInput.value;
        if (F.isNullOrWhitespace(email)) {
            //rely on the standard validator for required check
            return true;
        }
        if (!F.isValidEmailAddress(email)) {
            emailInput.setCustomValidity("Invalid email address format.");
            return false;
        }
        return true;
    }

    private static checkPhoneValid(form: any) {
        let phoneInput = $(form).find("#user-phone")[0] as HTMLInputElement;
        phoneInput.setCustomValidity("");
        let phone = phoneInput.value;
        // if (F.isNullOrWhitespace(phone)) {
        //     //rely on the standard validator for required check
        //     return true;
        // }
        // if (!F.isValidEmailAddress(email)) {
        //     emailInput.setCustomValidity("Invalid email address format.");
        //     return false;
        // }
        //phoneInput.setCustomValidity("Dev");
        return true;
    }
}

export default CreateUserForm;
