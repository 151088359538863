import React, { useEffect, useState } from 'react';
import './SubjectScanCard.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { F } from '../Functions';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { C } from '../Constants';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import { PatientActions } from '../ControllerActionHelpers/PatientActions';
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import { getLastFilename } from "../ControllerActionHelpers/httpHelper";
import BOXScore from '../BOXScore/BOXScore';
import ConcussionScanCard from '../ConcussionScanCard/ConcussionScanCard';
import CannabisScanCard from '../CannabisScanCard/CannabisScanCard';

interface SubjectScanCardState {
    scan: vms.IScan;
    session: vms.ISession;
    formResponsesExpanded: boolean;
}

interface SubjectScanCardProps {
    scan: vms.IScan;
    session: vms.ISession;
    userInfo?: vms.EyeBOXUser;
    patient?: vms.IPatient;
    onUpdating: (message: string) => void;
    onUpdated: () => void;
}

class SubjectScanCard extends React.Component<SubjectScanCardProps, SubjectScanCardState> {

    constructor(props: SubjectScanCardProps, state: SubjectScanCardState) {
        super(props);
        this.state = {
            scan: this.props.scan,
            session: this.props.session,
            formResponsesExpanded: false,
        };
    }

    render() {
        return <div className='SubjectScanCard'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        let isCannabis = F.isCannabisScan(this.state.scan);
        let isConcussion = F.isConcussionScan(this.state.scan);
        //in the future we might have to test other application types
        //for now, fallback to concussion if cannabis is not determined
        let extraPaddingClass = (isCannabis ? "extra-padding" : "");
        return (
        <div>
            <div className={extraPaddingClass}>
            {
                isCannabis
                ? <CannabisScanCard scan={this.state.scan} patient={this.props.patient}/>
                : isConcussion
                ? <ConcussionScanCard scan={this.state.scan}/>
                : <div>Application configuration not supported</div>

            }
            </div>
            <hr/>
            <div className={extraPaddingClass}>
            <div key="comments-title" className='comments-title'>
                Comments
            </div>            
            <div className='comment-scroller'>
                {this.buildScanComments()}
            </div>
            {
                (this.props.userInfo?.HasRole(vms.EyeBOXUserRole.Contributor))
                ? this.buildNewCommentButton()
                : null
            }
            </div>
        </div>);
    }

    private buildScanComments() {
        let result: Array<any> = [];
        if (this.state.scan.comments && this.state.scan.comments.length > 0) {
            this.state.scan.comments.map((comment, index, comments) => {
                result.push(this.buildCommentUI(comment))
            });
        }
        return result; 
    }

    private buildCommentUI(comment: vms.IScanComment) {
        let canUpdate = this.canUpdateComment(comment);

        return (<div key={comment.id} className=' comment-container'>            
                <div className='row no-gutters'>
                    <div className='col-11 data-value'>
                        {this.buildCommentTitle(comment)}
                    </div>
                    <div className='col-1'>
                        {this.buildCommentActionButtons(comment)}
                    </div>
                </div>
                <div className='row no-gutters'>
                    {
                        canUpdate
                        ? (
                            <div className={'col-12 left comment no-gutters'} onClick={e => {
                                this.onEditComment(comment);
                            }}>
                               "{comment.comment}"
                           </div>
                        )
                        : (
                            <div className={'col-12 left comment no-gutters readonly'}>
                               "{comment.comment}"
                           </div>
                        )
                    }
                </div>
        </div>);
    }

    private buildCommentActionButtons(comment: vms.IScanComment) {
        if (!this.canUpdateComment(comment)) {
            return <span />
        }
        return (
            <a title="Delete" className='comment-action' onClick={e => {
                this.onDeleteComment(comment);
            } }>
                <i className='fa fa-xmark'/>
            </a>
        )
    }

    private buildCommentTitle(comment: vms.IScanComment) {
        return (<span title={comment.contributorEmail} className='full-height'>
            <span className='vertical-middle'>
            {F.getDateString(comment.commentDate)}
            <span className='d-inline'>&nbsp;</span>
            {F.getTimeString(comment.commentDate)}
            <span className='d-inline'> - </span>
            {comment.contributorName ? comment.contributorName : comment.contributorEmail}
        </span></span>);
    }

    private async onNewComment(e: React.MouseEvent<HTMLAnchorElement>) {
        let newComment = await ApplicationModal.getMemo("", "Enter New Comment", "", "SAVE");
        if (F.isNullOrWhitespace(newComment)) {
            return;
        }

        this.props.onUpdating("Adding comment...");
        await PatientActions.updateScanComment(this.state.scan.patientId, this.state.scan.scanID, {
            comment: newComment!
        }).then(() => {
            this.props.onUpdated();
        }).catch(ControllerActions.reportError);        
    }

    private async onEditComment(comment: vms.IScanComment) {
        let editedComment = await ApplicationModal.getMemo("", "Edit Comment", comment.comment, "SAVE");
        if (editedComment == undefined || editedComment == comment.comment) {
            return;
        }
        this.props.onUpdating("Updating comment...");
        let temp: vms.IScanComment = F.clone(comment);
        temp.comment = editedComment;
        await PatientActions
            .updateScanComment(this.state.scan.patientId, this.state.scan.scanID, temp)
            .then(() => { this.props.onUpdated() })
            .catch(ControllerActions.reportError);        
    }

    private async onDeleteComment(comment: vms.IScanComment) {
        let doDelete = await ApplicationModal.confirm("Are you centain you want to delete this comment?", true);
        if (doDelete) {
            this.props.onUpdating("Deleting comment...");
            await PatientActions
            .deleteScanComment(this.state.scan.patientId, this.state.scan.scanID, comment.id!)
            .then(() => { this.props.onUpdated() })
            .catch(ControllerActions.reportError);
        }
    }

    private canUpdateComment(comment: vms.IScanComment) {
        return this.props.userInfo?.HasRole(vms.EyeBOXUserRole.Contributor)
            && (
                comment.contributorEmail == this.props.userInfo?.email
                || this.props.userInfo.HasRole(vms.EyeBOXUserRole.Admin, vms.EyeBOXUserRole.Super)
            );
    }

    private buildNewCommentButton() {
        return (<div className='row new-comment-button-row' key="new-comment-button-row">
            <a className='comment-action' onClick={e => {
                this.onNewComment(e);
            }}>
                <span className='action'><i className='fa fa-comment-medical'/>Add comment</span>
            </a>
        </div>);
    }

    private getBoxOrDefault() {
        return this.state.scan.box ? this.state.scan.box.toFixed(1) : "MISSING";
    }

    private getDeviceDisplay() {
        let temp = this.state.scan?.deviceId;
        if (F.isNullOrWhitespace(temp))
            return "";
        let dashIndex = temp.indexOf("-");
        if (dashIndex != -1) {
            let parts = temp.split("-");
            if (parts.length == 2)
                return parts[0];
        }
        return temp;
    }


}

export default SubjectScanCard;
