import React, { useEffect, useState } from 'react';
import './LogFileDownloadCriteriaForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';

interface LogFileDownloadCriteriaFormState extends vms.IUsageLogDownloadRequest {
    dateError?: boolean;
}

interface LogFileDownloadCriteriaFormProps {
    deviceModel: string;
    deviceSerial: string;
}

class LogFileDownloadCriteriaForm extends DialogFormBase<LogFileDownloadCriteriaFormProps, LogFileDownloadCriteriaFormState> {

    private static instance?: LogFileDownloadCriteriaForm = undefined;

    constructor(props: LogFileDownloadCriteriaFormProps, state: LogFileDownloadCriteriaFormState) {
        super(props);
        var now = new Date();
        var monthStart = now;
        var monthEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        this.state = {
            startDate: monthStart,
            endDate: monthEnd,
            deviceModel: props.deviceModel,
            deviceSerial: props.deviceSerial,
        };
        LogFileDownloadCriteriaForm.instance = this;
    }

    render() {
        return <div className='LogFileDownloadCriteriaForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.buildStandardDatePicker("start-date", "Start Date", this.state.startDate, this.startDateUpdated.bind(this), true)}
                {this.buildStandardDatePicker("end-date", "End Date", this.state.endDate, this.endDateUpdated.bind(this), this.state.dateError)}
                {this.checkIncludeDateValidationError()}
            </form>
        );
    }

    private checkIncludeDateValidationError() {
        if (!this.state.dateError) {
            return <span />
        }
        return <div className="validation-error">{"The End Date must be on or after the Start Date"}</div>
    }

    private startDateUpdated(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            startDate: new Date(e.target.value),
            dateError: undefined
        });
    }

    private endDateUpdated(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            endDate: new Date(e.target.value),
            dateError: undefined
        });
    }

    public static OnValidate(form: any): boolean {        
        let startDateInput = $(form).find("#start-date")[0] as HTMLInputElement;
        if (!startDateInput) {
            return false;
        }
        let startDate = new Date(startDateInput.value);

        let endDateInput = $(form).find("#end-date")[0] as HTMLInputElement;
        if (!endDateInput) {
            return false;
        }
        let endDate = new Date(endDateInput.value);

        let result = endDate >= startDate;
        if (!result) {
            LogFileDownloadCriteriaForm.instance?.setState({ dateError: true });
        }
        return result;
    }
}

export default LogFileDownloadCriteriaForm;
