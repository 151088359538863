import * as vms from "../ViewModels";
import {ControllerActions} from './ControllerActions'
import ApplicationModal from "../ApplicationModal/ApplicationModal";
import ApplicationToast from "../ApplicationToast/ApplicationToast";

export class PublicActions {

    public static async getNews() {
        var action = ControllerActions.buildUriAction("News/GetPublic");
        var result = await ControllerActions.doGetWithToken<Array<vms.INews>>(action);
        return result;
    }

    public static async unsubscribeFromEmail(email: string, otp: string) {
        var action = ControllerActions.buildUriAction("User/UnsubscribeFromEmail");
        var result = await ControllerActions.doPostWithToken(action, { email, otp });
        //console.log("unsubscribeFromEmail result", result);
        return result;
    }
}