import React, { ReactEventHandler, useEffect, useState } from 'react';
import './Super.css';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { PageActions } from "../PageActions";
import { UserActions } from "../ControllerActionHelpers/UserActions";
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import ApplicationTitle from '../ApplicationTitle/ApplicationTitle';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import CreateOrganizationForm from '../CreateOrganizationForm/CreateOrganizationForm';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import CreateNewsForm from '../CreateNewsForm/CreateNewsForm';
import { NewsActions } from '../ControllerActionHelpers/NewsActions';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import { F } from '../Functions';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { Debounce, IThrottledRequest } from '../ControllerActionHelpers/Debounce';
import { ConfigurationActions } from '../ControllerActionHelpers/ConfigurationActions';
import ConfigureSiteForm from '../ConfigureSiteForm/ConfigureSiteForm';
import DataExportCriteriaForm from '../DataExportCriteriaForm/DataExportCriteriaForm';
const { version } = require('../../package.json');

interface SuperState {
    organizations?: Array<vms.IOrganization>;
    currentUser: vms.EyeBOXUser;
    filter: string;
    availableOrgCount?: number;
    isFetching?: boolean;
    stillLoading?: boolean;
}

interface SuperProps extends vms.AuthenticatedUserProps {

}

class Super extends React.Component<SuperProps, SuperState> {

    constructor(props: SuperProps, state: SuperState) {
        super(props);
        this.state = {
            filter: "",
            currentUser: new vms.EyeBOXUser(props.userInfo),
            organizations: []
        };
    }

    render() {
        return <div className='Super'>
            <div className='row'>
                {this.BuildContent()}
            </div>
        </div>
    }

    componentDidMount() {
        //console.log("Super componentDidMount");
        PageActions.BeginUpdate();
        try {
            PageActions.Add("Configure Site", this.configureSite.bind(this));
            PageActions.Add("Build Usage Report", this.buildUsageReport.bind(this));
            PageActions.Add("About", async () => {
                ApplicationModal.showMessage(`Version ${version}`);
            })
            //PageActions.Add("Impersonate", this.impersonate.bind(this))
            //PageActions.Add("Refresh Orgs", this.getOrganizations.bind(this));
            //Do not include Create Org until one can be associated with a storage folder
            //PageActions.Add("Create Org", this.createOrg.bind(this));
            //PageActions.Add("Create News", this.createNews.bind(this));
            if (window.location.hostname == "localhost") {
                PageActions.Add("Copy User Token", this.copyUserToken.bind(this));
                PageActions.Add("Refresh Resource Cache", this.refreshResourceCache.bind(this));
                PageActions.Add("Log User Info", this.logUserInfo.bind(this));
            }
            // PageActions.Add("Toast Hello", () => this.toast("Hello "));
            // PageActions.Add("Long Toast", () => this.longToast(400, "Hello", "I'm", "a", "long", "toast", "where", "multiple", "lines", "are", "presented"));
            // PageActions.Add("Toast UI", () => this.toast("UI was built successfully!"));
        }
        finally {
            PageActions.EndUpdate();

            setTimeout(this.getOrganizations.bind(this), 0);
        }
    }

    componentWillUnmount() {
        //console.log("Super componentWillUnmount");
        PageActions.Clear();
    }

    private toast(message: string) {
        for (let i = 0; i < 1; i++) {//7 is a good max to test
            message += message;
        }
        ApplicationToast.Show(message);
    }

    private async longToast(delay: number, ...messages: Array<string>) {
        for (let i = 0; i < messages.length; i++) {
            ApplicationToast.Show(messages[i]);
            if (i < messages.length - 1) {
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        }
    }

    private BuildContent() {
        if (!this.state.currentUser.HasRole(vms.EyeBOXUserRole.Super)) {
            return <Navigate to="/" />
        }
        return this.buildOrganizationTable();
    }

    private showAddOrg() {
        ApplicationToast.Show("Need to add an org");
    }

    private buildOrganizationTable() {
        return (<div className='full-width'>
            <div className='detail-header no-gutters'>
                <div className='col-8'>
                    <div className="">
                        <input
                            type="text"
                            className='full-width'
                            value={this.state.filter}
                            onChange={e => this.onOrganizationFilterChange(e.target.value)}
                            placeholder="Filter organizations..." />
                    </div>
                    {/* <i className='fa fa-search'></i> */}
                </div>
                {/* <div className='col-1'></div> */}
                <div className='col-4 right'>
                    <a type='button' className='rounded-button-blue' onClick={() => this.createOrg()}>ADD ORGANIZATION</a>
                </div>
            </div>
            <br/>
            <div className='detail-body table-rounded-container'>
                <div className='row full-width table-header no-gutters'>
                    <div className='col-3'>Name</div>
                    <div className='col-2'>Type</div>
                    <div className='col-2'>Creation</div>
                    <div className='col-1'>{F.getSubjectsLabel()}</div>
                    <div className='col-1'>Users</div>
                    <div className='col-2'>Devices</div>
                    <div className='col-1'>&nbsp;</div>
                </div>
                <hr className='table-header-separator'/>
                    
                {
                    this.state.organizations
                        ? this.state.organizations?.map((org, index, organizations) => {
                            return (
                                <div key={organizations.length + "-" + index} className={'row no-gutters organization-row' + (org.isEnabled ? "" : " disabled-org")} >
                                    <div className='col-3 data-cell' onClick={e => this.checkEditOrg(e, org)}>{org.name}</div>
                                    <div className='col-2 data-cell' onClick={e => this.checkEditOrg(e, org)}>{F.getOrganizationTypeName(org.type)}</div>
                                    <div className='col-2 data-cell' onClick={e => this.checkEditOrg(e, org)}>{F.getDateString(org.creationDate)}</div>
                                    <div className='col-1 data-cell' onClick={e => this.checkEditOrg(e, org)}>{org.patientCount}</div>
                                    <div className='col-1 data-cell' onClick={e => this.checkEditOrg(e, org)}>{org.users? org.users.length : 0}</div>
                                    <div className='col-2 data-cell' onClick={e => this.checkEditOrg(e, org)}>{org.devices? org.devices.length : 0}</div>
                                    <div className='col-1 data-cell' onClick={e => this.checkEditOrg(e, org)}>{this.buildOrganizationOptionsButton(org)}</div>
                                </div>
                        )})
                        : null
                }

            </div>
            {this.buildMoreOrganizationsButton()}
        </div>
        );
    }

    private async checkEditOrg(e: React.MouseEvent<HTMLDivElement>, org: vms.IOrganization) {
        if ($(e.target).hasClass("data-cell")) {
            await this.editOrg(org);
        }
    }

    private buildOrganizationOptionsButton(org: vms.IOrganization) {
        return (
        <div className='dropdown'>
          <a className="h-ellipses-button data-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            >
            <span className='fa fa-ellipsis-h'></span>
          </a>
          <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
            <div className='dropdown-list'>
              <div>
                <a className='rounded-button-small default-menu-item' onClick={() => this.editOrg(org)}>
                    <span className='glyph-label'>
                        <span className='fa fa-up-right-from-square'></span>Edit
                    </span>                    
                </a>
              </div>
              <div>
                <a className='rounded-button-small' onClick={() => this.assignSelfToOrganization(org) }>
                    <span className='glyph-label'>
                        <span className='fa fa-search'></span>View
                    </span>                  
                </a>
              </div>
            </div>
          </div>
        </div>
        );
    }

    private async checkRemoveOrg(org: vms.IOrganization) {
        var doRemove = await ApplicationModal.confirm("This will remove '" + org.name + "' from the system.");
        if (doRemove) {
            ApplicationToast.Show("Need to remove the org somehow");
        }
    }

    private buildMoreOrganizationsButton() {
        if (this.state.stillLoading) {
            return <LoadingWidget message='Loading Organizations...' fontSize={20} compact={true} />;
        }
        let fetchedCount = this.state.organizations?.length ?? 0;
        if (fetchedCount < (this.state.availableOrgCount ?? 0)) {
            return (
                <div className='row full-width loaded-display'>
                    <div className='centered'>
                        { "Showing " + fetchedCount + " of " + (this.state.availableOrgCount ?? 0) }
                    </div>
                    <a className='rounded-button-blue' href='#' onClick={() => this.getOrganizations(fetchedCount)}>
                        LOAD MORE
                    </a>
                </div>
            );
        }
        return <br/>;
    }

    private fetchMore() {
        ApplicationToast.Show("Fetch more");
    }

    private onOrganizationFilterChange(newFilter: string) {
        this.setState({
            organizations: [],
            availableOrgCount: undefined,
            filter: newFilter
        });
        this.scheduleOrganizationRequest(newFilter);
    }

    static readonly OrganizationRequestName = "organization-filter-request";

    private scheduleOrganizationRequest(filter: string) {
        Debounce.AddRequest({
            name: Super.OrganizationRequestName,
            timeoutMs: 400,
            action: (request: IThrottledRequest) => {
                if (request.name != Super.OrganizationRequestName) {
                    console.log("Request name does not match allowed name.", request.name, Super.OrganizationRequestName);
                    return;
                }
                console.log("Debounce executed", request.name, request.tag);
                this.checkApplyFilter(request.tag);
            },
            tag: filter,
        });
    }

    private checkApplyFilter(filter?: string) {
        let applyFilter = false;
        if (F.isNullOrWhitespace(filter) && F.isNullOrWhitespace(this.state.filter)) {
            applyFilter = true;
        }
        else if (filter == this.state.filter) {
            applyFilter = true;
        }
        if (applyFilter) {            
            this.getOrganizations(0);
        }
    }

    private buildOrganizationTableHeader() {
        return (<div className='row col-11' key="org-table-header">
            <div className='col-3'>Organization Name</div>
            <div className='col-2'>Type</div>
            <div className='col-2'>Creation</div>
            <div className='col-2'>Subjects</div>
            <div className='col-2'>Devices</div>
            <div className='col-1'>&nbsp;</div>
        </div>);
    }

    private async getOrganizations(startIndex: number | undefined) {
        if (this.state.isFetching) {
            //wait for the existing fetch to return before sending another
            return;
        }
        let fetchedCount = this.state.organizations?.length ?? 0;
        if (undefined == startIndex) {
            startIndex = 0;
        }
        if (startIndex < fetchedCount) {
            return;
        }

        this.setState({isFetching: true, stillLoading: true});
        OrganizationActions.enumerate(this.state.filter, startIndex)
            .then(response => {
                this.setState({isFetching: false});
                if (response.filter != this.state.filter && !(F.isNullOrWhitespace(response.filter) && F.isNullOrWhitespace(this.state.filter))) {
                    console.log("Returned filter does not match the existing filter");
                    this.onOrganizationFilterChange(this.state.filter);                    
                    return;
                }
                var mergedOrgs = this.mergeResponseWithExistingOrganizations(response);
                this.setState({
                    organizations: mergedOrgs,
                    availableOrgCount: response.available,
                    isFetching: false,
                    stillLoading: false,
                });
            })
            .catch(error => {
                this.setState({isFetching: false, stillLoading: false});
                console.log(error);
            });
    }

    private mergeResponseWithExistingOrganizations(response: vms.IOrganizationResponse): Array<vms.IOrganization> {
        //if there was no prior response then use the returned records
        if (!this.state.organizations || this.state.organizations.length == 0) {
            return response.records;
        }
        //if the prior response matches the current response use the returned records (might be updated)
        if (this.state.organizations.length == response.records.length && 0 == response.startIndex) {
            return response.records;
        }
        let result: Array<vms.IOrganization> = F.clone(this.state.organizations);
        if (result.length == response.startIndex) {
            //console.log("received auxiliary page");
            result = result.concat(response.records);
        }
        else {
            //todo: replace a page? populate dummy rows? nothing?
            console.log("StartIndex: " + response.startIndex + ", ExistingCount: " + result.length);
        }
        return result;
    }

    private async createOrg() {
        var form: any;
        var add = await ApplicationModal.showForm(<CreateOrganizationForm ref={e => form = e ?? form} />,
            "New Organization", undefined, "SAVE");
        if (add) {
            await OrganizationActions.create(form.state)
                .then(async () => await this.refreshOrganizations())
                .catch(ControllerActions.reportError);
        }
    }

    private async editOrg(org: vms.IOrganization) {
        var form: any;
        var edit = await ApplicationModal.showForm(<CreateOrganizationForm initialOrg={org} ref={e => form = e ?? form} />,
            "Organization", undefined, "SAVE");
        if (edit) {
            await OrganizationActions.update(form.state)
                .then(async () => await this.refreshOrganizations())
                .catch(ControllerActions.reportError);
        }
    }

    private async createNews() {
        var form: any;
        var add = await ApplicationModal.showForm(<CreateNewsForm ref={e => form = e ?? form} isSuper={true} />, "Add News");
        if (add) {
            await NewsActions.create(form.state);
        }
    }

    private assignSelfToOrganization(org: vms.IOrganization) {
        if (!org.isEnabled) {
            ApplicationToast.Show("Temporary assignment to a disabled organization is not allowed.");
            return;
        }
        let organizationId: string = org.id;
        UserActions.setCurrentOrganization(organizationId)
            .then(updated => { if (updated) window.location.assign("/") })
            .catch(ControllerActions.reportError);
    }

    private logUserInfo() {
        console.log(this.props.userInfo);
    }

    private copyUserToken() {
        this.props.auth0.getAccessTokenSilently().then(token => navigator.clipboard.writeText(token));
    }

    private async refreshResourceCache() {        
        await OrganizationActions.resetResourceCache()
            .then(value => {
                ApplicationToast.Show("Resource Cache has been refreshed.");
            })
            .catch(ControllerActions.reportError);
    }

    private async refreshOrganizations() {
        this.setState({
            isFetching: false,
            organizations: [],
            availableOrgCount: undefined,
        }, async () => {
            await this.getOrganizations(0);            
        });
    }

    private async configureSite() {
        var form: any;
        var config = await ConfigurationActions.getConfiguration();
        if (!config) {
            ApplicationToast.Show("Unable to fetch the site's current configuration.");
            return;
        }
        var save = await ApplicationModal.showForm(
            <ConfigureSiteForm ref={e => form = e ?? form} initialConfig={config} />,
             "Site Configuration", undefined, "SAVE");
        if (save) {
            //include the new secondary message if the user never clicked Add
            var newMessage = form.state.newMessage;
            var config: vms.IWebsiteConfiguration = form.state;
            if (!F.isNullOrWhitespace(newMessage)) {
                config.secondaryMessages.push(newMessage)
            }
            for (var i = config.secondaryMessages.length - 1; i >= 0; i--) {
                if (F.isNullOrWhitespace(config.secondaryMessages[i])) {
                    config.secondaryMessages.splice(i, 1);
                }
            }
            //remove any model names that the user emptied
            var allModels: Array<vms.IDeviceModelInfo> = [];
            config.deviceModels.map((item, index, items) => {
                if (F.isNullOrWhitespace(item.name) && F.isNullOrWhitespace(item.imageFilename)) {
                    return;
                }
                allModels.push(item);
            });
            config.deviceModels = allModels;
            //now push the update to the server
            await ConfigurationActions.update(config);
        }
    }

    private async buildUsageReport() {
        var form: any;
        var doExport = await ApplicationModal.showForm(<DataExportCriteriaForm
            ref={e => { form = e ?? form; }}
        />, "Build Usage Report", (form) => {
            return DataExportCriteriaForm.OnValidate(form);
        });
        if (doExport) {
            const abortController = new AbortController();
            ApplicationModal.showCancelableSpin("Retrieving usage report...", abortController);
            await OrganizationActions.getUsageReport(form.state, abortController.signal)
            .then(file => {
                var url = URL.createObjectURL(file);
                let a = document.createElement("a") as HTMLAnchorElement;
                a.href = url;
                a.download = form.state.exportName + ".csv";
                a.click();
                URL.revokeObjectURL(url);
                ApplicationModal.close();
            })
            .catch(reason => {
                if (reason == ApplicationModal.AbortDownloadReason) {
                    return;
                }
                ControllerActions.reportError(reason);
            });
        }
    }

}

export default Super;
