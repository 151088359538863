import * as vms from "../ViewModels";
import { doGet, doPost } from './httpHelper';
import ApplicationModal from "../ApplicationModal/ApplicationModal";

export class ControllerActions {
    private static userToken?: string;

    public static setUserToken(userToken?: string) {
        this.userToken = userToken;
    }

    public static buildUriAction(controller: string, action?: string, ...parts: Array<string>){
        let result = controller;
        if (null != action) {
            result += "/" + action;
        }
        for (let i=0; i<parts.length; i++) {
            result += "/" + encodeURIComponent(parts[i]);
        }
        return result;
    }

    public static async doGetWithToken<T>(action: string): Promise<T> {
        return await doGet<T>(action, this.userToken!);
    }

    public static async doPostWithToken<T>(action: string, data: any, signal?: AbortSignal): Promise<T> {
        return await doPost<T>(action, this.userToken!, data, signal);
    }

    public static reportError(error: any): void {
        if (!error || !error.message) return;
        var msg = error.message + (error.cause ? "\n(" + error.cause + ")" : "");
        console.log(msg);
        ApplicationModal.showError(msg);
    }
}