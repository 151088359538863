import React, { useEffect, useState } from 'react';
import './MoveDeviceForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';

interface MoveDeviceFormState {
    newOrgId?: string;
}

interface MoveDeviceFormProps {
    originalOrgId?: string;
    organizations: Array<vms.IOrganizationBrief>;
}

class MoveDeviceForm extends DialogFormBase<MoveDeviceFormProps, MoveDeviceFormState> {
    constructor(props: MoveDeviceFormProps, state: MoveDeviceFormState) {
        super(props);
        this.state = {
            newOrgId: this.props.originalOrgId
        };
    }

    render() {
        return <div className='MoveDeviceForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.buildStandardSelect("model", "Destination Org:", this.state.newOrgId, this.buildOrganizationOptions(), e => this.setState({ newOrgId: e.target.value }), true)}
            </form>
        );
    }

    private buildOrganizationOptions(): Array<IOptionData> {
        let result: Array<IOptionData> = [];
        this.props.organizations.map((org, index, orgs) => {
            result.push(this.buildStandardOption(org.id, org.name ?? org.id));
        });
        return result;
    }
}

export default MoveDeviceForm;
