import React, { useEffect, useState } from 'react';
import './CannabisScore.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface CannabisScoreState {
}

interface CannabisScoreProps {
    scan: vms.IScan;
}

class CannabisScore extends React.Component<CannabisScoreProps, CannabisScoreState> {
    constructor(props: CannabisScoreProps, state: CannabisScoreState) {
        super(props);
        this.state = state;
    }

    render() {
        return <div className='CannabisScore'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        let rawScore = this.props.scan?.cannabis || -1;        
        let haveScore = rawScore != -1;
        let markerLeft = (1 + (98 * rawScore)) + "%"
        return (
            <div >
                {
                    haveScore
                        ? (<div className="gradient-container">
                            <div className="gradient-bar"></div>

                            <div className="marker" id="marker" style={{ left: markerLeft }}>
                                <div className="value-label" id="value-label">{rawScore.toFixed(2)}</div>
                                <div className="pointer"></div>
                            </div>
                            <div className="labels upper">
                                <span>0.0</span>
                                <span>0.25</span>
                                <span>0.5</span>
                                <span>0.75</span>
                                <span>1.0</span>
                            </div>
                            <div className="labels lower">
                                <span>Very Low</span>
                                <span>Low</span>
                                <span>Normal</span>
                            </div>
                        </div>)
                        : <div className='box-label error'>Cannabis score could not be calculated.</div>
                }
            </div>
        ); 
    }
}                    

export default CannabisScore;
    