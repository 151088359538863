import React, { useEffect, useState } from 'react';
import './CreateOrganizationForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';
import { F } from '../Functions';

interface CreateOrganizationFormState extends vms.IOrganization {
    submitAttempted: boolean;
}

interface CreateOrganizationFormProps {
    initialOrg?: vms.IOrganization,
}

class CreateOrganizationForm extends DialogFormBase<CreateOrganizationFormProps, CreateOrganizationFormState> {
    constructor(props: CreateOrganizationFormProps, state: CreateOrganizationFormState) {
        super(props);
        if (props.initialOrg) {
            var temp = F.clone(props.initialOrg);
            temp.submitAttempted = false;
            this.state = temp;
        }
        else {
            this.state = {
                id: state.id,
                creationDate: state.creationDate,
                name: state.name,
                submitAttempted: false,
                isEnabled: false,
            }
        }
    }

    render() {
        return <div className='CreateOrganizationForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.buildStandardInput("org-name", "Organization Name", this.state.name!, e => { this.setState({name: e.target.value}) }, true)}
                {this.buildStandardSelect("org-type", "Organization Type",
                    this.state.type ? Number(this.state.type).toString() : undefined, this.buildOptions(), e => {
                        this.setState({type: e.target.value ? Number(e.target.value) : undefined})
                }, true)}
                {this.buildStandardCheckbox("org-is-enabled", "Enabled", this.state.isEnabled, e => { this.setState({isEnabled: e.target.checked})}, true)}
                {this.buildStandardInput("org-contact-name", "Contact Name", this.state.contactName, e => { this.setState({contactName: e.target.value}) }, true, () => false)}
                {this.buildStandardInput("org-contact-email", "Contact Email", this.state.contactEMail, e => { this.setState({contactEMail: e.target.value}) }, true, () => false)}
                {
                this.buildStandardPhoneInput("org-contact-phone", "Contact Phone", this.state.contactPhone, newValue => {
                    this.setState({contactPhone: newValue})
                }, true, () => false)
                }
                {this.buildStandardTextArea("org-notes", "Notes", this.state.notes, 3, e => { this.setState({notes: e.target.value})}, false, () => false)}
            </form>
        );
    }

    private buildOptions() {
        let result: Array<IOptionData> = [];
        result.push(this.buildOption("", "Select..."));
        this.getOrganizationTypeValues().forEach((item, index, items) => {
            result.push(this.buildOption(item.toString(), vms.OrganizationType[item]));
        });
        return result;
    }

    private buildOption(value: string, text: string): IOptionData {
        return { value, text };
    }

    private getOrganizationTypeValues(): Array<number> {
        let result: Array<number> = [];
        for (let item in vms.OrganizationType) {
            let num = Number(item);
            if (!isNaN(num)) {
                result.push(num);
            }
        }
        return result;
    }
}

export default CreateOrganizationForm;
