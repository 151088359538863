import React, { useEffect, useState } from 'react';
import './ApplicationTitle.css';
import * as vms from '../ViewModels';
import { ConfigurationActions } from '../ControllerActionHelpers/ConfigurationActions';

interface ApplicationTitleState {
    content?: any;
    title?: string;
}

interface ApplicationTitleProps {
    content?: any;
    title?: string;
}

class ApplicationTitle extends React.Component<ApplicationTitleProps, ApplicationTitleState> {
    constructor(props: ApplicationTitleProps, state: ApplicationTitleState) {
        super(props);
        this.state = {
            content: props.content
        };
        ApplicationTitle.instance = this;
    }

    private static instance: ApplicationTitle;

    public static Clear() {
        this.set();
    }

    public static Set(props: ApplicationTitleProps) {
        this.set(props);
    }

    private static set(props?: ApplicationTitleProps) {
        if (!this.instance) {
            return;
        }
        window.setTimeout(() => {
            this.instance.setState({
                content: props?.content
            });
        }, 0);
    }

    render() {
        return <div className='ApplicationTitle'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <div>
                <div>{this.state.title}</div>
                {this.state.content ? <div>{this.state.content}</div> : null}
            </div>
        );
    }
}

export default ApplicationTitle;
