import { abort } from "process";
import internal from "stream";

export interface IThrottledRequest {
    name: string;
    action: { (request: IThrottledRequest): void };
    timeoutMs: number;
    timeout?: number;
    tag?: any;
}

export class Debounce {

    private static adding : boolean;
    private static requests: { [name: string]: IThrottledRequest | undefined } = {};

    public static AddRequest(request: IThrottledRequest) {
        let existingRequest = this.requests[request.name];
        if (existingRequest && existingRequest.timeout) {            
            if (existingRequest.tag == request.tag) {
                //just wait for the already queued request to execute
                //console.log("Debounce", "Existing request tag matches new request tag");
                return;
            }
            //console.log("resetting request: " + request.name);
            clearTimeout(existingRequest.timeout);
        }
        else {
            //console.log("adding request: " + request.name);
        }
        this.requests[request.name] = request;
        request.timeout = window.setTimeout((requestArg: IThrottledRequest) => {
            let requestName = requestArg.name;
            //console.log("requestArg", requestArg);
            //console.log("executing request: " + requestArg.name, requestArg);
            let pendingRequest = this.requests[requestName];
            if (pendingRequest) {
                pendingRequest.action(pendingRequest);
                this.requests[requestName] = undefined;
            }
        }, request.timeoutMs, request);
    }
}