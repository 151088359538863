import React, { useEffect, useState } from 'react';
import './DataExportCriteriaForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase from '../DialogFormBase/DialogFormBase';
import { F } from "../Functions";

interface DataExportCriteriaFormState extends vms.IDataExportCriteria {
    dateError?: boolean;
}

interface DataExportCriteriaFormProps {
}

class DataExportCriteriaForm extends DialogFormBase<DataExportCriteriaFormProps, DataExportCriteriaFormState> {
    private static instance?: DataExportCriteriaForm = undefined;

    constructor(props: DataExportCriteriaFormProps, state: DataExportCriteriaFormState) {
        super(props);
        var now = new Date();
        var monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        var monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        //console.log("Month range: " + monthStart.toDateString() + " to " + monthEnd.toDateString());
        this.state = {
            startDate: monthStart,
            endDate: monthEnd,      
        };
        DataExportCriteriaForm.instance = this;
    }

    render() {
        return <div className='DataExportCriteriaForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.buildStandardInput("export-name", "Export Name", this.state.exportName, this.exportNameUpdated.bind(this), true)}
                {this.buildStandardDatePicker("start-date", "Start Date", this.state.startDate, this.startDateUpdated.bind(this), true)}
                {this.buildStandardDatePicker("end-date", "End Date", this.state.endDate, this.endDateUpdated.bind(this), this.state.dateError)}
                {this.checkIncludeDateValidationError()}
            </form>
        );
    }

    private checkIncludeDateValidationError() {
        if (!this.state.dateError) {
            return <span />
        }
        return <div className="validation-error">{"The End Date must be on or after the Start Date"}</div>
    }

    private startDateUpdated(e: React.ChangeEvent<HTMLInputElement>) {
        //console.log("startDateUpdated", e.target, e.target.value);
        this.setState({
            startDate: new Date(e.target.value),
            dateError: undefined
        });
    }

    private endDateUpdated(e: React.ChangeEvent<HTMLInputElement>) {
        //console.log("endDateUpdated", e.target, e.target.value);
        this.setState({
            endDate: new Date(e.target.value),
            dateError: undefined
        });
    }

    private exportNameUpdated(e: React.ChangeEvent<HTMLInputElement>) {
        //console.log("exportNameUpdated", e.target, e.target.value);
        this.setState({
            exportName: e.target.value
        });
    }

    public static OnValidate(form: any): boolean {        
        let startDateInput = $(form).find("#start-date")[0] as HTMLInputElement;
        //console.log(startDateInput);
        if (!startDateInput) {
            return false;
        }
        let startDate = new Date(startDateInput.value);
        //console.log(startDate);

        let endDateInput = $(form).find("#end-date")[0] as HTMLInputElement;
        //console.log(endDateInput);
        if (!endDateInput) {
            return false;
        }
        let endDate = new Date(endDateInput.value);
        //console.log(endDate);

        let result = endDate >= startDate;
        if (!result) {
            DataExportCriteriaForm.instance?.setState({ dateError: true });
        }
        return result;
    }

}

export default DataExportCriteriaForm;
