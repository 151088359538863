import React, { useEffect, useState } from 'react';
import './Admin.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels'
import { Navigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

interface AdminState {
    userToken: string;
    userInfo: vms.IEyeBOXUser;
}

interface AdminProps extends vms.AuthenticatedUserProps {

}

class Admin extends React.Component<AdminProps, AdminState> {
    constructor(props: AdminProps, state: AdminState) {
        super(props);
        this.state = {
            userInfo: props.userInfo,
            userToken: props.userToken
        };
    }

    render() {
        return <div className='Admin'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        var ebxUser = new vms.EyeBOXUser(this.state.userInfo);
        if (!ebxUser.HasRole(vms.EyeBOXUserRole.Admin)) {
            return <Navigate to="/" />
        }
        return "Admin Content";
    }
}

export default Admin;
