import React, { useEffect, useState } from 'react';
import './UserCard.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IViewModel } from "../ViewModels";
import { ControllerActions } from "../ControllerActionHelpers/ControllerActions";
import ApplicationModal from "../ApplicationModal/ApplicationModal";
import { F } from '../Functions';
import CreateUserForm from '../CreateUserForm/CreateUserForm';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import { C } from '../Constants';
import { UserActions } from '../ControllerActionHelpers/UserActions';
import ApplicationToast from '../ApplicationToast/ApplicationToast';

interface UserCardState {
    user: vms.EyeBOXUser;
}

interface UserCardProps {
    user: vms.EyeBOXUser;
    currentUser: vms.EyeBOXUser;
    onUpdated: { (): void };
}

class UserCard extends React.Component<UserCardProps, UserCardState> {
    constructor(props: UserCardProps, state: UserCardState) {
        super(props);
        this.state = {
            user: props.user
        };
    }

    render() {
        return <div className='UserCard'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        let user = this.state.user;
        return (
            <div className='row no-gutters'>
                <div className='col-3 col-md-1'  onClick={e => this.checkEditUser(e)}>
                    <i className='fa fa-user'></i>
                </div>
                <div className='col-8 col-md-4'  onClick={e => this.checkEditUser(e)}>
                    <div className='row data-label'>{this.state.user.name}</div>
                    <div className='row data-value'>{this.state.user.email}</div>
                    <div className='row data-value'>{this.state.user.phone}</div>
                    <div className='row data-value'>Last Login: {F.getDateString(this.state.user.lastLogin)}</div>
                </div>                
                <div className='col-1 no-hand d-block d-md-none'>
                    {this.buildUserOptionsButton()}
                </div>
                <div className='col-12 col-md-4'  onClick={e => this.checkEditUser(e)}>
                    <div className='row data-label'>Roles:</div>
                    {this.state.user.roles?.map((role, index, roles) => {
                        return <div key={roles.length + "-" + index} className='row'>{vms.EyeBOXUserRole.getDescription(role)}</div>
                    })}
                    <div className='row d-md-none' style={{height:"10px"}}></div>
                </div>
                <div className='col-12 col-md-2'  onClick={e => this.checkEditUser(e)}>
                    <div className='row data-label'>Notifications:</div>
                    <div className='row'>{this.getNotificationTypeDescription(this.state.user.notificationTypes)}</div>
                </div>
                <div className='col-1 no-hand d-none d-md-block'>
                    {this.buildUserOptionsButton()}
                </div>
            </div>
        );
    }

    private buildUserOptionsButton() {
        return (
        <div className='dropdown'>
          <a className="h-ellipses-button data-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            >
            <span className='fa fa-ellipsis-h'></span>
          </a>
          <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
            <div className='dropdown-list'>
              <div>
                <a className='rounded-button-small default-menu-item' onClick={e => {
                    this.editUser();
                }}>
                    <span className='glyph-label'>
                        <span className='fa fa-up-right-from-square'></span>Edit
                    </span>                    
                </a>
              </div>
              <div>
                <a className='rounded-button-small' onClick={e => {
                    this.deleteUser();
                 } }>
                    <span className='glyph-label'>
                        <span className='fa fa-xmark'></span>Delete
                    </span>                  
                </a>
              </div>
              <div>
                <a className='rounded-button-small' onClick={e => {
                    this.resendInvitation();
                 } }>
                    <span className='glyph-label'>
                        <span className='fa fa-envelope'></span>Invite
                    </span>                  
                </a>
              </div>
            </div>
          </div>
        </div>
        );
    }


    private getNotificationTypeDescription(notificationTypes?: Array<string>) {
        if (!notificationTypes || notificationTypes.length == 0) {
            return "None";
        }
        return notificationTypes.join(", ");
    }

    private async checkEditUser(e: React.MouseEvent<HTMLDivElement>) {
        if ($(e.target).hasClass("fa-ellipsis-h") || $(e.target).hasClass("glyph-label")) {
            return;
        }
        this.editUser();
    }

    private async editUser() {
        var isSelf = false;
        if (this.props.currentUser.email == this.state.user.email && !this.state.user.HasRole(vms.EyeBOXUserRole.Super)) {
            //only restrict input for the user's own profile if they are not a Super user
            isSelf = true;
        }
        var isSuper = this.props.currentUser.HasRole(vms.EyeBOXUserRole.Super);
        var form: any;
        var edit = await ApplicationModal.showForm(<CreateUserForm
            ref={e => { form = e ?? form; }}
            isSuper={isSuper}
            initialValues={F.clone(this.state.user)}
            isCreating={false}
            isSelf={this.state.user.email == this.props.currentUser.email}
        />, (this.state.user.email == this.props.currentUser.email ?  "My Profile" : "Edit User"), (form) => {
            //todo: include prevention of Super or Admin disabling themself
            return CreateUserForm.OnValidate(form);
        });
        if (edit) {
            if (isSelf) {
            await UserActions.updateSelf(form.state)
                .then(updatedUser => {
                    if (updatedUser) {
                        this.setState({ user: updatedUser });
                    }
                    ApplicationToast.Show("Your profile was updated");
                })
                .catch(ControllerActions.reportError);
            }
            else {
            await UserActions.update(form.state)
                .then(updatedUser => {
                    if (updatedUser) {
                        this.setState({ user: updatedUser });
                    }
                })
                .catch(ControllerActions.reportError);
            }
        }

    }

    private async deleteUser() {
        if (!this.props.currentUser.CanUpdateUsers()) {
            ApplicationToast.Show("You are not authorized to delete users.");
            return;
        }
        if (this.state.user.userId == this.props.currentUser.userId) {
            ApplicationToast.Show("You are not allowed to delete your own record");
            return;
        }
        var doDelete = await ApplicationModal.confirm("Delete user '" + this.state.user.email + "'?", true);
        if (!doDelete) {
            return;
        }
        ApplicationToast.Show("Deleting user '" + this.state.user.email + "'");
        var deleted = await UserActions.delete(this.state.user);
        if (deleted) {
            ApplicationToast.Show("Successfully deleted user '" + this.state.user.email + "'");
            this.props.onUpdated();
        }
        else {
            ApplicationToast.Show("Error encountered while deleting user '" + this.state.user.email + "'");
        }
    }

    private async resendInvitation() {
        if (!this.props.currentUser.CanUpdateUsers()) {
            ApplicationToast.Show("You are not authorized to email users.");
            return;
        }
        if (this.state.user.userId == this.props.currentUser.userId) {
            ApplicationToast.Show("You are not allowed to email yourself.");
            return;
        }
        var doSend = await ApplicationModal.confirm(`Resend invitation to '${this.state.user.email}'?`, true);
        if (!doSend) {
            return;
        }
        ApplicationToast.Show(`Sending invitation to '${this.state.user.email}'.`);
        var sent = await UserActions.resendInvitation(this.state.user);
        if (sent) {
            ApplicationToast.Show("Successfully queued the invation for delivery.");
            //we don't need to refresh the page            
        }
        else {
            ApplicationToast.Show(`Error encountered while queueing invitation for ${this.state.user.email}`);
        }
    }
}

export default UserCard;
