import React, { useEffect, useState } from 'react';
import './BOXScore.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface BOXScoreState {
}

interface BOXScoreProps {
    scan: vms.IScan;
}

class BOXScore extends React.Component<BOXScoreProps, BOXScoreState> {
    constructor(props: BOXScoreProps, state: BOXScoreState) {
        super(props);
        this.state = state;
    }

    render() {
        return <div className='BOXScore'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        let box = this.props.scan?.box || -1;
        let haveBOX = box != -1;
        let lessThan10 = (box <= 10.0);
        let boxPct = (haveBOX ? box / 20.0 : 0.0) * 100;
        return (
            <div >
                <div className='box-label'>BOX Score<sup>TM</sup></div>
                {
                    haveBOX
                    ? (<div>
                <div className='box-bar'>
                    <div className='marker' style={{left: boxPct + "%"}}>
                        <div className='marker-circle'/>
                        <div className={'marker-callout-tie-in ' + (lessThan10 ? "less-than-10" : "greater-than-10")}/>
                        <div className={'marker-callout ' + (lessThan10 ? "less-than-10" : "greater-than-10")}>
                            {this.props.scan.box?.toFixed(1)} (of 20)
                        </div>
                    </div>
                </div>
                <div className='box-scale'>
                    <div style={{textAlign: "left"}}>0</div>
                    <div style={{textAlign: "center"}}>10</div>
                    <div style={{textAlign: "right"}}>20</div>
                </div>
                    </div>)
                    : <div className='box-label error'>BOX score could not be calculated.</div>
                }
            </div>
        );
    }
}

export default BOXScore;
