type InactivityCallback = () => void;

class UserInactivityTracker {
  private readonly maxInactivityTime: number;
  private inactivityTimeout: NodeJS.Timeout | null = null;
  private readonly onInactive: InactivityCallback;

  constructor(maxInactivityTime: number, onInactive: InactivityCallback) {
    this.maxInactivityTime = maxInactivityTime;
    this.onInactive = onInactive;

    this.resetInactivityTimer = this.resetInactivityTimer.bind(this);
    this.startInactivityTimer();
    this.setupEventListeners();
  }

  private resetInactivityTimer(): void {
    // Reset the inactivity timer whenever user interacts
    //console.log('User is active. Resetting inactivity timer.');
    this.startInactivityTimer();
  }

  private startInactivityTimer(): void {
    // Clear existing timeout if any
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }

    // Start a new timeout for max inactivity time
    this.inactivityTimeout = setTimeout(() => {
      //console.log(`User is inactive for more than ${this.maxInactivityTime} seconds.`);
      this.onInactive(); // Invoke the inactivity callback
    }, this.maxInactivityTime * 1000); // Convert seconds to milliseconds
  }

  private setupEventListeners(): void {
    // Listen for user interactions to reset the inactivity timer
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
    window.addEventListener('click', this.resetInactivityTimer);
    window.addEventListener('scroll', this.resetInactivityTimer);
    window.addEventListener('touchstart', this.resetInactivityTimer);
  }

  // Cleanup method to remove event listeners and clear timeout
  public cleanup(): void {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
      this.inactivityTimeout = null;
    }
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
    window.removeEventListener('click', this.resetInactivityTimer);
    window.removeEventListener('scroll', this.resetInactivityTimer);
    window.removeEventListener('touchstart', this.resetInactivityTimer);
  }
}

export default UserInactivityTracker;
