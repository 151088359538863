import * as vms from "../ViewModels";
import { ControllerActions } from "./ControllerActions";
import ApplicationToast from "../ApplicationToast/ApplicationToast";
import { C } from "../Constants";

export class UserActions {

    private static buildUserAction(action: string, ...parts: Array<string>){
        return ControllerActions.buildUriAction("User", action, ...parts);
    }

    // public static async endImpersonation() {
    //     var action = this.buildUserAction("EndImpersonation");
    //     var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, { });
    //     return result != null;
    // }

    // public static async impersonate(email?: string) {
    //     var action = this.buildUserAction("Impersonate");
    //     var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, { email });
    //     return result != null;
    // }

    // public static async setSelfName(name: string) {
    //     var action = this.buildUserAction("SetSelfName");
    //     var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, { name });
    //     return result;
    // }

    public static async getMe() {
        var action = this.buildUserAction("GetMe");
        var result = await ControllerActions.doGetWithToken<vms.IEyeBOXUser>(action);
        return result;
    }

    public static async enumerate() {
        //console.log("getPatients", filter, pageIndex, pageSize);
        var action = this.buildUserAction("Enumerate");
        var result = await ControllerActions.doGetWithToken<Array<vms.IEyeBOXUser>>(action);
        return result;
    }

    public static async add(user: vms.IEyeBOXUser) {
        // if (!await this.confirm("This will add " + email + " as a user in the current organization.")) {
        //     return false;
        // }
        ApplicationToast.Show("Adding " + user.email + " to the organization...");
        var action = this.buildUserAction("Add");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, user);
        if (result != null) {
            ApplicationToast.Show("User assignment successful.");
        }
        return result;
    }

    public static async update(user: vms.IEyeBOXUser): Promise<vms.EyeBOXUser | undefined> {
        // if (!await this.confirm("This will add " + email + " as a user in the current organization.")) {
        //     return false;
        // }
        ApplicationToast.Show("Updating " + user.email);
        var action = this.buildUserAction("Update");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, user);
        if (result != null) {
            ApplicationToast.Show("User update successful.");
            return new vms.EyeBOXUser(result);
        }
        return undefined;
    }

    public static async setCurrentOrganization(organizationId: string) {
        var action = this.buildUserAction("SetCurrentOrganization");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, { organizationId });
        return result;
    }

    public static async unsetCurrentOrganization() {
        var action = this.buildUserAction("UnsetCurrentOrganization");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, { });
        return result;
    }

    public static async delete(user: vms.IEyeBOXUser) {
        var action = this.buildUserAction("Delete");
        var result = await ControllerActions.doPostWithToken<Boolean>(action, user);
        return result;
    }

    private static toastAssignment(childType: string, organizationId: string) {
        let message = organizationId == C.Unassigned
            ? "Unassigning " + childType + " from the selected organization. To reassign, find the " + childType + " in Unassigned users and sevices."
            : "Assigning " + childType + " to the selected organization. To unassign, find the " + childType + " in the target organization.";
        ApplicationToast.Show(message);
    }

    public static async move(userId: string, organizationId: string) {
        // if (!await this.confirmAssignment("user", organizationId)) {
        //     return false;
        // }
        this.toastAssignment("user", organizationId);
        var action = this.buildUserAction("Move");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, {
                userId,
                newOrganizationId: organizationId
             });
        if (result != null) {
            ApplicationToast.Show("User assignment successful.");
        }
        return result != null;
    }

    public static async updateSelf(user: vms.IEyeBOXUser) {
        var action = this.buildUserAction("UpdateSelf");
        var result = await ControllerActions.doPostWithToken<vms.IEyeBOXUser>(action, user);
        return new vms.EyeBOXUser(result);
    }

    public static async resendInvitation(user: vms.IEyeBOXUser) {
        var action = this.buildUserAction("ResendInvitation");
        var result = await ControllerActions.doPostWithToken<boolean>(action, user);
        return result;
    }

    public static async updatePassword(request: vms.IUpdatePasswordRequest): Promise<vms.IUpdatePasswordResponse> {
        var action = this.buildUserAction("UpdatePassword");
        var result = await ControllerActions.doPostWithToken<vms.IUpdatePasswordResponse>(action, request);
        return result;
    }

    public static async resetPassword(request: vms.IUpdatePasswordRequest): Promise<vms.IUpdatePasswordResponse> {
        var action = this.buildUserAction("ResetPassword");
        var result = await ControllerActions.doPostWithToken<vms.IUpdatePasswordResponse>(action, request);
        return result;
    }

    public static async validatePasswordToken(email: string, resetToken: string): Promise<vms.IUpdatePasswordResponse> {
        var action = this.buildUserAction("ValidatePasswordToken");
        var result = await ControllerActions.doPostWithToken<vms.IUpdatePasswordResponse>(action, {
            email,
            resetToken
        });
        return result;
    }

    public static async requestPasswordReset(email: string): Promise<vms.IUpdatePasswordResponse> {
        var action = this.buildUserAction("RequestPasswordReset");
        var result = await ControllerActions.doPostWithToken<vms.IUpdatePasswordResponse>(action, {email});
        return result;
    }

}