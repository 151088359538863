import { Debounce } from "./ControllerActionHelpers/Debounce";

/*
    PageScroller is a common location shared by the App, which owns the scrolling
    region, and the child pages, which may need to be notified of scroll events.
*/
export enum ScrollPosition { Start, Middle, End }

type ScrollListener = (position: ScrollPosition, offset: number, max: number) => void;

export class PageScroller {
    private static scroller: any = undefined;
    private static listeners: Array<ScrollListener> = [];

    public static Monitor(scroller: any): void {
        this.scroller = scroller;
        this.scroller.onscroll = () => {
            Debounce.AddRequest({
                name: "page-scroll-monitor",
                action: () => {
                    //console.log("scrolled");
                    this.OnScroll();            
                },
                timeoutMs: 50
            })
        }
        const resizeObserver = new ResizeObserver(() => {
            Debounce.AddRequest({
                name: "page-scroll-monitor",
                action: () => {
                    //console.log("resized");
                    this.OnScroll();            
                },
                timeoutMs: 50
            })
        });
        resizeObserver.observe(this.scroller);
    }

    private static OnScroll() {
        if (!this.scroller) {
            return;
        }
        let position: ScrollPosition = ScrollPosition.Middle;
        let offset = this.scroller.scrollTop;
        let max = this.scroller.scrollHeight - this.scroller.clientHeight;
        if (offset == 0) {
            position = ScrollPosition.Start;
        }
        else if (offset == max) {
            position = ScrollPosition.End;
        }

        for (var listener of this.listeners) {
            try {
                listener(position, offset, max);
            }
            catch {
                this.RemoveListener(listener);
            }
        }
}

    private static RemoveListener(handler: ScrollListener) {
        let handlerIndex = this.listeners.indexOf(handler);
        if (handlerIndex != -1) {
            this.listeners.splice(handlerIndex, 1);
        }
    }

    public static Clear() {
        this.listeners = [];
    }

    public static Listen(handler: ScrollListener): { (): void } {
        this.listeners.push(handler);
        //return an unlisten method
        return () => { this.RemoveListener(handler); };
    }

    public static IsVisible() {
        if (!this.scroller) {
            return false;
        }
        return this.scroller.scrollHeight > this.scroller.clientHeight;
    }
}
