import React, { useEffect, useState } from 'react';
import './SubjectCard.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import { F } from "../Functions";
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import { ModalDialog } from 'react-bootstrap';

interface SubjectCardState {
}

interface SubjectCardProps {
    patient: vms.IPatient;
    isContributor: boolean;
}

class SubjectCard extends React.Component<SubjectCardProps, SubjectCardState> {
    constructor(props: SubjectCardProps, state: SubjectCardState) {
        super(props);
        this.state = state;
    }

    render() {
        return <div className='SubjectCard'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        let scanTypes = F.determinePatientScanTypes(this.props.patient);
        return (
            <Link className='patient-link' to={"/organization/subjectdetail?" + this.props.patient.id}>
                {
                    scanTypes.isAll()
                    ? this.buildCompositeSubjectCardLayout()
                    : scanTypes.cannabis
                    ? this.buildCannabisSubjectCardLayout()
                    : scanTypes.concussion
                    ? this.buildConcussionSubjectCardLayout()
                    : <div>Application configuration not supported</div>
                }
                
            </Link>
        );
    }

    private buildConcussionSubjectCardLayout() {
        return (<div className=''>
            <div className='row no-gutters'>
                <div className='col-2 large-icon'>
                    <i className='fa fa-hospital-user'></i>
                </div>
                <div className='col-10'>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>{"" + F.getSubjectLabel() + ":"}</div>
                        <div className='col-8 left data-val'>{F.buildPatientNameOrAltIDs(this.props.patient)}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>ID:</div>
                        <div className='col-8 left data-val'>{this.props.patient?.eyeBOXPatientId}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Age:</div>
                        <div className='col-8 left data-val'>{this.props.patient?.age}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Gender:</div>
                        <div className='col-8 left data-val'>{this.props.patient?.gender}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Last Scan:</div>
                        <div className='col-8 left data-val'>{F.getDateString(this.props.patient.lastScanDate)}</div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    private buildCannabisSubjectCardLayout() {
        let mostRecentScan = this.getMostRecentScan(this.props.patient);
        return (<div className=''>
            <div className='row no-gutters'>
                <div className='col-2 large-icon'>
                    <i className='fa fa-hospital-user'></i>
                </div>
                <div className='col-10'>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Age:</div>
                        <div className='col-8 left data-val'>{this.props.patient.age ?? "N/A"}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Gender:</div>
                        <div className='col-8 left data-val'>{this.props.patient?.gender}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Result:</div>
                        <div className='col-8 left data-val'>
                            { (mostRecentScan ? this.getLastScanResult(mostRecentScan) : "Scan not found") }
                        </div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Last Scan:</div>
                        <div className='col-8 left data-val'>
                            {F.getDateString(this.props.patient.lastScanDate)}
                            <br/>
                            { `(${mostRecentScan ? mostRecentScan.scanID : "Scan not found"})` }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    private buildCompositeSubjectCardLayout() {
        let mostRecentScan = this.getMostRecentScan(this.props.patient);
        return (<div className=''>
            <div className='row no-gutters'>
                <div className='col-2 large-icon'>
                    <i className='fa fa-hospital-user'></i>
                </div>
                <div className='col-10'>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>{"" + F.getSubjectLabel() + ":"}</div>
                        <div className='col-8 left data-val'>{F.buildPatientNameOrAltIDs(this.props.patient)}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>ID:</div>
                        <div className='col-8 left data-val'>{this.props.patient?.eyeBOXPatientId}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Age:</div>
                        <div className='col-8 left data-val'>{this.props.patient.age ?? "N/A"}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Gender:</div>
                        <div className='col-8 left data-val'>{this.props.patient?.gender}</div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Result:</div>
                        <div className='col-8 left data-val'>
                            { (mostRecentScan ? this.getLastScanResult(mostRecentScan) : "Scan not found") }
                        </div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-4 data-label'>Last Scan:</div>
                        <div className='col-8 left data-val'>
                            {F.getDateString(this.props.patient.lastScanDate)}
                            <br/>
                            { `(${mostRecentScan ? mostRecentScan.scanID : "Scan not found"})` }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    private getMostRecentScan(patient: vms.IPatient) {
        var lastScan: vms.IScan | undefined = undefined;
        for (var session of patient.sessions) {
            if (!session.scans) {
                continue;
            }
            for (var scan of session.scans) {
                if (!lastScan ||  lastScan.scanDateTime < scan.scanDateTime) {
                    lastScan = scan;
                }
            }
        }
        return lastScan;
    }

    private getLastScanResult(scan: vms.IScan) {

        return `${(scan.cannabis ?? 0.0).toFixed(2)} (${vms.Normality[scan.normality]})`;
    }

}

export default SubjectCard;
