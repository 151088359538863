import React, { useEffect, useState } from 'react';
import './Landing.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import jwt_decode from "jwt-decode";
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import ApplicationTitle from "../ApplicationTitle/ApplicationTitle";
import { PublicActions } from '../ControllerActionHelpers/PublicActions';
import { NewsActions } from '../ControllerActionHelpers/NewsActions';
import { F } from '../Functions';
import { ConfigurationActions } from '../ControllerActionHelpers/ConfigurationActions';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import UnsubscribeEmailView from '../UnsubscribeEmailView/UnsubscribeEmailView';

interface LandingState {
    userToken?: string;
    userTokenInfo?: any;
    userInfo?: vms.IEyeBOXUser;
    news?: Array<vms.INews>;
    configuration?: vms.IWebsiteConfiguration;
}

interface LandingProps extends WithAuth0Props {
    userInfo?: vms.IEyeBOXUser;
    userToken?: string;
}

class Landing extends React.Component<LandingProps, LandingState> {
    constructor(props: LandingProps, state: LandingState) {
        super(props);
        this.state = {
            userInfo: props.userInfo,
            userToken: props.userToken,
        };
    }

    render() {
        if (this.props.auth0.isLoading) {
            return <div>Loading...</div>
        }
        if (this.props.auth0.error) {
            return <div>{this.props.auth0.error.message}</div>
        }
        return <div className='Landing'>{this.BuildContent()}</div>
    }

    componentDidMount() {
        this.checkFetchNews();
        this.checkFetchStaticContent();
    }

    componentWillUnmount() {
        ApplicationTitle.Clear();
    }

    private BuildContent() {
        if (UnsubscribeEmailView.isQueryPresent()) {
            return <UnsubscribeEmailView />
        }

        if (!this.props.auth0.isAuthenticated) {
            if (!this.state.configuration) {
                return <div><LoadingWidget message='Loading content...'></LoadingWidget></div>
            }
            return (<div>
                <div className='content-frame full-width middle'>
                    <div className=''>
                        {this.buildPrimaryMessage()}
                        <br/>                        
                        {this.buildSecondaryMessageList()}
                    </div>
                    <br/>
                    <div className='full-width middle'>
                        <a className='rounded-button-blue' onClick={e => {
                            F.showLogin(this.props.auth0);
                        }}>SIGN IN</a>
                        <br/>
                        <br/>
                        <div className='sign-in-text-heavy'>Don't have an account yet?</div>
                        <div className='sign-in-text-light'>Contact us at&nbsp;
                            <a href={`mailto:${window.domainConfig?.supportEmail || 'support@oculogica.com'}?subject=${window.domainConfig?.supportEmailSubject || ('Cloud: ' + window.location.hostname.toLowerCase())}`}>
                                {window.domainConfig?.supportEmail || 'support@oculogica.com'}
                            </a>
                        </div>
                        <div className='sign-in-text-light'>or call us at {window.domainConfig?.contactPhone || '(484) 393-2694'}</div>
                    </div>
                </div>
            </div>)
        }
        let ebxUser = this.state.userInfo
            ? new vms.EyeBOXUser(this.state.userInfo)
            : new vms.EyeBOXUser({roles: [], enabled: false, stoppedViaSms: false, confirmedProfile: false});
        if (!ebxUser.enabled || !(ebxUser.roles?.length)) {
            return (
                <div className='col-lg-12'>
                    Account unavailable or disabled. Please contact your administrator.
                </div>
            );
        }
        if ((ebxUser.roles ?? []).length == 0 ||
            (!this.state.userInfo?.organizationId && !ebxUser.HasRole(vms.EyeBOXUserRole.Super))
            ) {
            return (
                <div className='col-lg-12'>
                    <div>{this.state.userInfo?.organizationId != null ? "Organization assigned!" : "You are not currently assigned to an organization." }</div>
                    <div>{(ebxUser.roles ?? []).length > 0 ? "Role(s) assigned!" : "You are not currently assigned any roles." }</div>
                </div>
            )
        }
        if (ebxUser.HasRole(vms.EyeBOXUserRole.Super) && F.isNullOrWhitespace(ebxUser.currentOrganizationId)) {
            return <Navigate to="/super" />
        }
        return <Navigate to="/organization" />
    }

    private buildPrimaryMessage() {
        return (
        <div className='row primary-message'>
            <ul className='primary-item-list'>
                <li>{this.state.configuration?.primaryMessage}</li>
            </ul>            
        </div>);
    }

    private buildSecondaryMessageList() {
        return (
            <ul className='secondary-item-list'>
                {this.state.configuration?.secondaryMessages?.map((value, index, msgs) => {
                    return <li key={msgs.length + "-" + index}>{value}</li>
                })}
            </ul>
        );
    }

    private fetchingNews: boolean = false;
    private async checkFetchNews() { 
        return;
        if (this.fetchingNews) {
            return;
        }
        this.fetchingNews = true;
        var news = await PublicActions.getNews();
        this.setState({
            news: news
        });
        this.fetchingNews = false;
    }

    private fetchingStaticContent: boolean = false;
    private async checkFetchStaticContent() {
        if (this.fetchingStaticContent) {
            return;
        }
        this.fetchingStaticContent = true;
        var config = await ConfigurationActions.getConfiguration();
        this.setState({
            configuration: config
        });
        this.fetchingStaticContent = false;
    }
}

export default Landing;
