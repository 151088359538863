import * as vms from "../ViewModels";
import {ControllerActions} from './ControllerActions'
import ApplicationModal from "../ApplicationModal/ApplicationModal";
import ApplicationToast from "../ApplicationToast/ApplicationToast";

export class ConfigurationActions {

    private static current: vms.IWebsiteConfiguration | undefined = undefined;

    private static buildConfigurationAction(action: string, ...parts: Array<string>){
        return ControllerActions.buildUriAction("Configuration", action, ...parts);
    }

    //this is actually handled in the public controller
    public static async getConfiguration() {
        var action = this.buildConfigurationAction("GetConfiguration");
        this.current = await ControllerActions.doGetWithToken<vms.IWebsiteConfiguration>(action);
        this.onUpdated();
        return this.current;
    }

    public static async update(config: vms.IWebsiteConfiguration) {
        ApplicationToast.Show("Updating configuration...");
        var action = this.buildConfigurationAction("Update");
        var result = await ControllerActions.doPostWithToken<vms.IWebsiteConfiguration>(action, config);
        if (result != null) {
            this.current = result;
            this.onUpdated();
            ApplicationToast.Show("Configuration update successful.");
        }
        return result != null;
    }

    public static getCurrent() {
        return this.current;
    }

    private static updatedListeners: Array<() => void> = [];
    public static addUpdateListener(callback: () => void) {
        this.updatedListeners.push(callback);
    }

    private static onUpdated() {
        this.updatedListeners.forEach((callback) => callback());
    }
}