import React, { useEffect, useState } from 'react';
import './SecondaryTitle.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface SecondaryTitleState {
    content?: any;
}

interface SecondaryTitleProps {
    content?: any;
}

class SecondaryTitle extends React.Component<SecondaryTitleProps, SecondaryTitleState> {
    constructor(props: SecondaryTitleProps, state: SecondaryTitleState) {
        super(props);
        this.state = {
            content: props.content
        };
        SecondaryTitle.instance = this;
    }

    private static instance: SecondaryTitle;

    public static Clear() {
        this.set();
    }

    public static Set(props: SecondaryTitleProps) {
        this.set(props);
    }

    private static set(props?: SecondaryTitleProps) {
        window.setTimeout(() => {
            this.instance.setState({
                content: props?.content
            });
        }, 0);
    }

    render() {
        return <div className='SecondaryTitle'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <div>
                {this.state.content ? <div>{this.state.content}</div> : null}
            </div>
        );
    }
}

export default SecondaryTitle;